import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { MdPreview } from "react-icons/md";
import MasterDisplay from "../components/view-master/MasterDisplay";
import { Button, Modal, Select, MenuItem, Typography } from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { useDemoData } from "@mui/x-data-grid-generator";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GridToolbar } from "@mui/x-data-grid-premium";

import {
  GridRowModes,
  DataGrid,
  // GridToolbarContainer,
  GridToolbarQuickFilter,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import DynamicFormDialog from "./dynamic_form_dialogue";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// const DropdownCell = ({ value }) => (
//   <Select
//     value=""
//     displayEmpty
//     renderValue={() => "Raw Material Data"}
//     variant="standard"
//   >
//     {value.map((item, index) => (
//       <MenuItem key={index} value={item}>
//         {item}
//       </MenuItem>
//     ))}
//   </Select>
// );

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f4f6f8",
            color: "#637381",
            fontSize: 14,
            fontFamily: ["Public Sans, sans-serif"].join(","),
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-withBorderColor": {
            borderRight: 0,
            borderLeft: 0,
          },
          "& .MuiDataGrid-cell": {
            borderColor: "gray",
            borderRight: 0,
            borderLeft: 0,
            color: "#212B36",
            fontSize:14,
            fontWeight: 400,
            fontFamily: ["Public Sans, sans-serif"].join(","),
            borderBottom: "solid 1px rgba(145, 158, 171, 0.24)",
          },
         
        },
      },
    },
  },
});


export default function DynamicSubProduct(props) {
  const [rows, setRows] = React.useState(props?.data ?? []);
  const [rowModesModel, setRowModesModel] = React.useState({});
  // const [updateData, setUpdateData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [updateForm, setUpdateForm] = React.useState({});
  const [openDetail, setOpenDetail] = React.useState(false);
  const [masterData, setMasterData] = React.useState({});
  // const [expandedRows, setExpandedRows] = React.useState({});

  const { data, loading } = useDemoData({
    rowLength: 100,
    editable: true,
  });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setRows(props?.data);
  }, [props?.data]);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    const updateData = rows.filter((row) => row.id === id);
    const newData = updateData[0];
    setUpdateForm(newData);
    setOpen(true);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    props.handleDelete(id);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleOtherDetails = (id) => () => {
    const showData = rows.filter((item) => item.id === id);
    const newData = showData[0];
    setMasterData(newData);
    setOpenDetail(true);
  };

  // const toggleRowExpansion = (id) => {
  //   setExpandedRows((prevExpandedRows) => ({
  //     ...prevExpandedRows,
  //     [id]: !prevExpandedRows[id],
  //   }));
  // };

  const columns = [
    // ...props.column,
    { field: "product_name", headerName: "Product Name", width: 150 },
    { field: "product_alias", headerName: "Product Alias", width: 150 },
    {
      field: "raw_material_name",
      headerName: "Raw Material Name",
      width: 200,
      valueGetter: (params) => params.row?.map_details.map(Raw => Raw.raw_material_name).join(", "),
    },
    {
      field: "stage_name",
      headerName: "Stage Name",
      width: 200,
      valueGetter: (params) => params.row?.map_details.map(stage => stage.stage_name).join(", "),
    },
    {
      field: "sub_stage_name",
      headerName: "Sub Stage Name",
      width: 200,
      valueGetter: (params) => params.row?.map_details.map(sub => sub.sub_stage_name).join(", "),
    },
    {
      field: "stage_sequence_number",
      headerName: "Stage Sequence No.",
      width: 150,
      valueGetter: (params) => params.row?.map_details.map(Sequence => Sequence.stage_sequence_number).join(", "),
    },
    // {
    //   field: "raw_material_name",
    //   headerName: "Raw Material Name",
    //   width: 200,
    //   valueGetter: (params) => params.row?.map_details.map(Raw => Raw.raw_material_name).join(", "),
    //   renderCell: (params) => (
    //     <Box sx={{ maxWidth: 200, overflow: "auto", maxHeight: 100 }}>
    //       {params.row?.map_details.map((Raw, index) => (
    //         <Typography key={index} variant="body2">
    //           {Raw.raw_material_name}
    //         </Typography>
    //       ))}
    //     </Box>
    //   ),
    // },
    // {
    //   field: "stage_name",
    //   headerName: "Stage Name",
    //   width: 200,
    //   valueGetter: (params) => params.row?.map_details.map(stage => stage.stage_name).join(", "),
    //   renderCell: (params) => (
    //     <Box sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
    //       {params.row?.map_details.map((stage, index) => (
    //         <Typography key={index} variant="body2">
    //           {stage.stage_name}
    //         </Typography>
    //       ))}
    //     </Box>
    //   ),
    // },
    // {
    //   field: "sub_stage_name",
    //   headerName: "Sub Stage Name",
    //   width: 200,
    //   valueGetter: (params) => params.row?.map_details.map(sub => sub.sub_stage_name).join(", "),
    //   renderCell: (params) => (
    //     <Box sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
    //       {params.row?.map_details.map((sub, index) => (
    //         <Typography key={index} variant="body2">
    //           {sub.sub_stage_name}
    //         </Typography>
    //       ))}
    //     </Box>
    //   ),
    // },
    // {
    //   field: "stage_sequence_number",
    //   headerName: "Stage Sequence No.",
    //   width: 150,
    //   valueGetter: (params) => params.row?.map_details.map(Sequence => Sequence.stage_sequence_number).join(", "),
    //   renderCell: (params) => (
    //     <Box sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
    //       {params.row?.map_details.map((Sequence, index) => (
    //         <Typography key={index} variant="body2">
    //           {Sequence.stage_sequence_number}
    //         </Typography>
    //       ))}
    //     </Box>
    //   ),
    // },
    { field: "tax_percentage", headerName: "Tax Percentage", width: 150 },
    // { field: 'file_id', headerName: 'File', width: 200 },
    { field: "tax_name", headerName: "Tax Name", width: 150 },
    { field: "cgst_percentage", headerName: "CGST Percentage", width: 150 },
    { field: "sgst_percentage", headerName: "SGST Percentage", width: 150 },
    { field: "igst_percentage", headerName: "IGST Percentage", width: 150 },
    { field: "hsn_code", headerName: "HSN Code", width: 150 },
    
    // {
    //   field: "map_details",
    //   headerName: "Raw Material",
    //   width: 300,
    //   valueGetter: (params) =>
    //     params.row.map_details
    //       ? params.row.map_details.map(
    //           (d) =>
    //             `${d.raw_material_name}  (${d.stage_name})  (${d.sub_stage_name})  (${d.stage_sequence_number})`
    //         )
    //       : [],
    //   renderCell: (params) => <DropdownCell value={params.value} />,
    // },
    // {
    //   field: "map_details",
    //   headerName: "Raw Material",
    //   width: 200,
    //   valueGetter: (params) => {
    //     if (!params.row?.map_details || !Array.isArray(params.row.map_details)) {
    //       return [];
    //     }
    //     return params.row.map_details.map((d) => (
    //       <div className="flex flex-col">
    //         <span className="grid grid-cols-4">
    //           <span className="col-span-1">Raw Material Name </span>
    //           <span className="col-span-3">: {d.raw_material_name}</span>
    //         </span>
    //         <span className="grid grid-cols-4">
    //           <span className="col-span-1">Stage Name </span>
    //           <span className="col-span-3">: {d.stage_name}</span>
    //         </span>
    //         <span className="grid grid-cols-4">
    //           <span className="col-span-1">Sub Stage Name </span>
    //           <span className="col-span-3">: {d.sub_stage_name}</span>
    //         </span>
    //         <span className="grid grid-cols-4">
    //           <span className="col-span-1">Stage Sequence Number </span>
    //           <span className="col-span-3">: {d.stage_sequence_number}</span>
    //         </span>
    //       </div>
    //     ));
    //   },
    //   renderCell: (params) => params.row?.map_details ? <DropdownCell value={params.value} /> : null,
    // },

  
   
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 110,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          //   <GridActionsCellItem
          //     icon={<EditIcon sx={{ color: "#673ab7" }} />}
          //     label="Edit"
          //     className="textPrimary"
          //     onClick={handleEditClick(id)}
          //     color="inherit"
          //   />,
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: "red" }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<MdPreview size={25} />}
            label="View"
            onClick={handleOtherDetails(id)}
            color="primary"
          />,
        ];
      },
    },
  ];

  //   if (props.canView) {
  //     const len = columns.length;
  //     const obj = {
  //       field: "view_details",
  //       type: "actions",
  //       headerName: "View Details",
  //       width: 150,
  //       cellClassName: "actions",
  //       getActions: ({ id }) => [
  //         <Button onClick={handleOtherDetails(id)}>
  //           <MdPreview size={25} />
  //         </Button>,
  //       ],
  //     };
  //     columns.splice(len - 1, 0, obj);
  //   }

  const handleChange = (field, value) => {
    setUpdateForm((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    // console.log("change");
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await props.handleUpdate(e, updateForm);
      setOpen(false);
    } catch (error) {}
  };

  const filteredList = columns.filter((item) => item.hide === true);
  const hiddenCols = filteredList.reduce((acc, item) => {
    acc[item.field] = false;
    return acc;
  }, {});

  return (
    <ThemeProvider theme={theme}>
      <>
        <div>
          <Modal
            open={openDetail}
            // onClose={()=>{setOpenDetail(false)}}
          >
            <React.Fragment>
              <MasterDisplay
                handleClose={() => {
                  setOpenDetail(false);
                }}
                header="sub-product"
                data={masterData}
                // images={masterData.images}
              />
            </React.Fragment>
          </Modal>
        </div>
        <div>
          <Box>
            <DynamicFormDialog
              open={open}
              title="Edit Data"
              handleClose={handleClose}
              formState={updateForm}
              handleCheckBox={props.handleCheckBox}
              handleChange={handleChange}
              handleSave={handleUpdate}
              formConfig={props.column}
            />
          </Box>
        </div>

        <div>
          <Link to="/Masters">
            {/* <button className="text-white bg-suchi hover:bg-suchi-hover focus:ring-1 focus:ring-purple-300 font-medium rounded-md text-base px-5 py-2 mb-2 mt-6">
            <KeyboardArrowLeft />
          </button> */}
          </Link>
          <div className="mt-4 flex justify-between mx-2">
          <h1  style={{fontSize:"20px",fontWeight:"700",color: "#212B36",fontFamily: ["Public Sans, sans-serif"].join(",") }}>
          {props.title}
            </h1>
            <button
              type="button"
              onClick={props.toggleForm}
              className="text-white bg-suchi hover:bg-suchi-hover focus:ring-1 focus:ring-purple-300 font-medium rounded-md text-base px-5 py-2"
            >
              ADD DATA
            </button>
          </div>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                // height: 500,
                mt: 3,
                width: "100%",
                background: "#fff",
                overflow: "auto", // Enable scrolling
                maxHeight: 600,

                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },

                flex: 1,
              }}
            >
              {/* {rows?.length > 0 && ( */}
                <DataGrid
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 20, 50, 100]}
                  loading={loading}
                  getRowId={(row) => row?.id}
                  rows={rows}
                  columns={columns}
                  
                  editMode="row"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStart={handleRowEditStart}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  slotProps={{
                    toolbar: { setRows, setRowModesModel },
                  }}
                  slots={{
                    toolbar: (props) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <GridToolbar {...props} />
                        <div>
                          <QuickSearchToolbar {...props} />
                        </div>
                      </div>
                    ),
                  }}
                />              
            </Box>
          </Box>
        </div>
      </>
    </ThemeProvider>
  );
}
