  import React, { useEffect, useState } from "react";
  import { IoMdCloseCircle } from "react-icons/io";
  import { toast } from "react-toastify";
  import {
    addSupplierImage,
    addClientImage,
    addMachineImage,
    addEmployeeImage,
    addRawMaterialImage,
    addSubProductImage,
    addProductImage,
    addCompanyImage,
  } from "../../services/handlers/add_masters";

  const UploadImage = ({ name, handleChange, imageType }) => {
    const [images, setImages] = useState([]);
    const [fileNames, setFileNames] = useState([]);

    useEffect(() => {
      fileNames?.length > 0 && handleChange("file_name", fileNames);
    }, [fileNames]);

    // const handleFileChange = (e) => {
    //   const files = Array.from(e.target.files);
    //   setImages((prevImages) => [...prevImages, ...files]);
    //   handleChange("file_name", images);
    // };

    // const handleFileChange = (e) => {
    //   const files = Array.from(e.target.files);
    //   setImages((prevImages) => {
    //     const newImages = [...prevImages, ...files];
    //     handleChange("file_name", newImages);
    //     return newImages;
    //   });
    // };

    const handleFileChange = (e) => {
      const files = Array.from(e.target.files);
      console.log("files File Name Array", files);
      setImages((prevImages) => {
        const newImages = [...prevImages, ...files];
        return newImages;
      });
    };
    // console.log("File Name Array: ", fileNames);

    const uploadFiles = async (e) => {
      e.preventDefault();
      if (images.length > 0) {
        const uploadPromises = images.map(async (image) => {
          const uploadImagePayload = new FormData();
          uploadImagePayload.append("image", image);
          let response;
          switch (imageType) {
            case "supplier":
              response = await addSupplierImage(uploadImagePayload);
              break;
            case "client":
              response = await addClientImage(uploadImagePayload);
              break;
            case "company":
              response = await addCompanyImage(uploadImagePayload);
              break;
            case "machine":
              response = await addMachineImage(uploadImagePayload);
              break;
            case "employee":
              response = await addEmployeeImage(uploadImagePayload);
              break;
            case "raw-material":
              response = await addRawMaterialImage(uploadImagePayload);
              break;
            case "sub-product":
              response = await addSubProductImage(uploadImagePayload);
              break;
            case "product":
              response = await addProductImage(uploadImagePayload);
              break;
            default:
              toast.error("Invalid image type");
          }
          console.log("files File Name Array", response);
          if (response) {
            setFileNames((prevFileNames) => [...prevFileNames, response]);
          } else {
            console.error("Response does not contain filename:", response);
          }
        });
        await Promise.all(uploadPromises);
      } else {
        toast.error("No images to upload.");
      }
    };

    const removeImage = (index) => {
      const updatedImages = [...images];
      const updatedFileNames = [...fileNames];
      
      updatedImages.splice(index, 1);
      updatedFileNames.splice(index, 1);
      
      setImages(updatedImages);
      setFileNames(updatedFileNames);
      handleChange("file_name", updatedFileNames);
    };

    
    return (
      <div className="ml-4 mt-2 col-span-2" >
        <div className="form-group">
          <label
            className="block mb-2 text-base font-medium text-gray-900"
            htmlFor="file_input"
          >
            Upload {name} :
          </label>
          <input
            className="block w-50 text-base text-gray-900 border border-gray-300 rounded-md cursor-pointer bg-gray-50 focus:outline-none"
            id="file_input"
            type="file"
            onChange={handleFileChange}
            multiple
            accept="image/*,video/*"
          />
        </div>
        <div className="flex flex-wrap mt-4">
          {
            images &&
              Array.from(images).map((item, index) => {
                return (
                  <div  key={index} className="relative">
                    <img
                      key={item}
                      src={item ? URL.createObjectURL(item) : null}
                      alt="..."
                      className="w-40 h-50 object-cover mr-2 mb-2"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        removeImage(index);
                      }}
                      className="absolute top-1 right-3"
                    >
                      <IoMdCloseCircle className="text-red-500 text-2xl" />
                    </button>
                  </div>
                );
              })
          }
        </div>

        <button
          type="button"
          onClick={uploadFiles}
          className="text-white bg-suchi hover:bg-suchi-hover focus:ring-1 focus:ring-purple-300 font-medium rounded-md text-base px-5 py-2 "
        >
          Upload
        </button>
      </div>
    );
  };

  export default UploadImage;
