import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useCallback } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import { Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import { DynamicForm } from "./form/dynamic_form";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import UploadImage from "./form/UploadImage";

export default function DynamicRawMaterials({
  formState,
  formConfig,
  handleCheckBox,
  name,
  open,
  fullScreen,
  handleClose,
  handleSave,
  title,
  handleChange,
  rawmaterialList,
  handleRawMaterialChange,
  handleAddRawMaterial,
  rawmaterial,
  stage,
  subStage,
  TaxList,
  tax,
  handleTax,
  rawMaterialErrors,
  stageErrors,
  subStageErrors,
  taxErrors,
  handlerawmaterialListRemove,
  ProductNameAlias,
  handleAliasChange,
  handleNameChange,
}) {
  console.log("stage", JSON.stringify(stage));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ "& .MuiDialog-paper": { width: "65%", maxWidth: "none" } }}
    >
      <form onSubmit={handleSave}>
        <AppBar sx={{ position: "relative",backgroundColor:'#3ABEF9' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 ,color: '#fff',}} variant="h7" component="div">
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DynamicForm
            formState={formState}
            handleChange={handleChange}
            handleSave={handleSave}
            formConfig={formConfig}
            handleCheckBox={handleCheckBox}
          />
        </DialogContent>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          <Box sx={{ ml: 2, display: "flex", gap: 1 }}>
            {ProductNameAlias?.map((item, index) => (
              <Box key={index} sx={{ display: "flex", gap: 1, width: "100%" }}>
                <Box sx={{ width: "50%" }}>
                  <TextField
                    label="Product Name"
                    name="product_name"
                    value={item.product_name}
                    onChange={(e) =>
                      handleNameChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <TextField
                    label="Product Alias"
                    name="product_alias"
                    value={item.product_alias}
                    onChange={(e) =>
                      handleAliasChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogContent  sx={{ pb: 1, pt: 1 }}>
          <Box sx={{ ml: 2, display: "flex", gap: 1 }}>
            {TaxList?.map((item, index) => (
              <Box key={index} sx={{ gap: 2, width: "50%" }}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={tax}
                    getOptionLabel={(option) => option.tax_percentage}
                    value={
                      tax.find((s) => s.id === item?.tax_percentage_id_fk) ||
                      null
                    }
                    onChange={(event, newValue) =>
                      handleTax(
                        index,
                        "tax_percentage_id_fk",
                        newValue?.id || ""
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tax Percentage"
                        error={taxErrors[index]}
                        helperText={
                          taxErrors[index] ? "This field is required" : ""
                        }
                        required
                      />
                    )}
                    fullWidth
                    required
                  >
                    {tax?.map((e) => (
                      <MenuItem key={e?.id} value={e?.id}>
                        {e?.tax_percentage}
                      </MenuItem>
                    ))}
                  </Autocomplete>
                </FormControl>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          {formConfig.map((field) => {
            if (field.type === "image") {
              if (!field.imageType) {
                return null;
              }
              return (
                <UploadImage
                  key={field.field}
                  field={field.field}
                  multiple={field.multiple}
                  name={field.headerName}
                  handleChange={handleChange}
                  required={field.required}
                  imageType={field.imageType}
                />
              );
            }
            return null;
          })}
        </DialogContent>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          <Box sx={{ ml: 2 }}>
            <span className="block mb-2 text-base font-medium text-gray-900">
              {name} Raw Materials :
              <IconButton
                onClick={handleAddRawMaterial}
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                <AddCircleOutline />
              </IconButton>
            </span>
            <div>
              {rawmaterialList?.map((item, index) => (
                <Box key={index} sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <FormControl sx={{width: "21%"}}>
                    <Autocomplete
                      options={rawmaterial}
                      getOptionLabel={(option) => option.raw_material_name}
                      value={
                        rawmaterial.find(
                          (s) => s.id === item?.raw_material_id_fk
                        ) || null
                      }
                      onChange={(event, newValue) =>
                        handleRawMaterialChange(
                          index,
                          "raw_material_id_fk",
                          newValue?.id || ""
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Raw Materials"
                          error={rawMaterialErrors[index]}
                          helperText={
                            rawMaterialErrors[index]
                              ? "This field is required"
                              : ""
                          }
                          required
                        />
                      )}
                      fullWidth
                      required
                    >
                      {rawmaterial?.map((e) => (
                        <MenuItem key={e?.id} value={e?.id}>
                          {e?.raw_material_name}
                        </MenuItem>
                      ))}
                    </Autocomplete>
                  </FormControl>
                  <FormControl sx={{width: "21%"}}>
                    <Autocomplete
                      options={stage}
                      getOptionLabel={(option) => option.stage_name}
                      // label=" Stage "
                      // name="stage_id_fk"
                      value={
                        stage.find((s) => s.id === item?.stage_id_fk) || null
                      }
                      onChange={(event, newValue) =>
                        handleRawMaterialChange(
                          index,
                          "stage_id_fk",
                          newValue?.id || ""
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Stage"
                          error={stageErrors[index]}
                          helperText={
                            stageErrors[index] ? "This field is required" : ""
                          }
                          required
                        />
                      )}
                      fullWidth
                      required
                    >
                      {stage?.map((e) => (
                        <MenuItem key={e?.id} value={e?.id}>
                          {e?.stage_name}
                        </MenuItem>
                      ))}
                    </Autocomplete>
                  </FormControl>
                  <FormControl sx={{width: "21%"}}>
                    <Autocomplete
                      options={subStage}
                      getOptionLabel={(option) => option.sub_stage_name}
                      value={
                        subStage.find((s) => s.id === item?.sub_stage_id_fk) ||
                        null
                      }
                      onChange={(event, newValue) =>
                        handleRawMaterialChange(
                          index,
                          "sub_stage_id_fk",
                          newValue?.id || ""
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub Stage"
                          error={subStageErrors[index]}
                          helperText={
                            subStageErrors[index]
                              ? "This field is required"
                              : ""
                          }
                          required
                        />
                      )}
                      fullWidth
                      required
                    >
                      {subStage?.map((e) => (
                        <MenuItem key={e?.id} value={e?.id}>
                          {e?.sub_stage_name}
                        </MenuItem>
                      ))}
                    </Autocomplete>
                  </FormControl>
                  {/* <FormControl fullWidth margin="normal"> */}
                  <TextField
                    label=" Stage Sequence Number"
                    name="stage_sequence_number"
                    // type="Number"
                    value={item?.stage_sequence_number}
                    onChange={(e) =>
                      handleRawMaterialChange(
                        index,
                        e.target.name,
                        e.target.value
                      )
                    }
                    error={item.stage_sequence_number < 0 || item.stage_sequence_number > 9}
                    helperText={item.stage_sequence_number < 0 || item.stage_sequence_number > 9 ? "Must be between 0 and 9" : ""}
                    fullWidth
                    required
                    sx={{width: "21%"}}
                  >
                  </TextField>
                  {/* {index === rawmaterialList.length - 1 && (
                    <IconButton onClick={handleAddRawMaterial} sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                      <AddCircleOutline />
                    </IconButton>
                  )} */}
                  {index !== rawmaterialList.length - 1 && (
                    <IconButton onClick={() => handlerawmaterialListRemove(index)} sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              ))}
            </div>
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 3 , pb:2 }}>
          <Button
            sx={{
              color: '#fff',
              minWidth: 100,
              backgroundColor: "#3ABEF9",
              "&:hover": {
                backgroundColor: "#3572EF",
              },
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{ minWidth: 100,borderColor:"#3ABEF9", "&:hover": {
              borderColor: "#3572EF",
            }, color:"#3ABEF9" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
