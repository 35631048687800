import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import styled from "@mui/system/styled";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import RHFSelect from "../../components/hook-form/RHFSelect";
import RHFTextField from "../../components/hook-form/RHFTextField";
import { Edit } from "@mui/icons-material";
import DialogActions from "@mui/material/DialogActions";

import {
  getStages,
  getsubStages,
  getRawStage,
  getEmployeeName,
  getMachineName,
} from "../../services/handlers/get_masters";
import { addAssembly } from "../../services/handlers/add_masters";
import { toast } from "react-toastify";

const defaultValues = {
  stage: "",
  subStage: "",
  CameFromStage: -1,
  rawName: "",
  employee: "",
  machine: "",
  pcs: "",
  weight: "",
};
const changeStage = Yup.object().shape({
  rawName: Yup.string().trim().required("Required"),
  machine: Yup.string().trim().required("Required"),
  employee: Yup.string().trim().required("Required"),
});
const BorderBox = styled(Box)({
  color: "darkslategray",
  // backgroundColor: 'aliceblue',
  padding: 8,
  borderRadius: 4,
  marginTop: 25,
  border: "solid gray 1px",
});

const ChangeStageView = ({ setTableData, onClose, selectedRow, openModal, headerTitle }) => {
  const [rawName, setRawName] = useState([]);
  const [stage, setStage] = useState([]);
  const [camestage, setCameStage] = useState([]);
  const [subStage, setSubStage] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [machine, setMachine] = useState([]);
  const methods = useForm({
    resolver: yupResolver(changeStage),
    defaultValues,
  });
  const { reset, getValues, setValue } = methods;

  useEffect(() => {
    fetchInitialData();
  }, [openModal]);

  useEffect(() => {
  if (selectedRow?.detail) {
    setValue("id", selectedRow?.detail?.id || "");
    setValue("rawName", selectedRow?.detail?.raw_material_id_fk || "");
    setValue("employee", selectedRow?.detail?.employee_id_fk || "");
    setValue("machine", selectedRow?.detail?.machine_id_fk || "");
    setValue("stage", selectedRow?.detail?.stage_id_fk || "");
    setValue("CameFromStage", selectedRow?.detail?.came_from_stage_id_fk || -1);
    setValue("subStage", selectedRow?.detail?.sub_stage_id || "");
    setValue("pcs", selectedRow?.detail?.qty_pcs || "");
    setValue("weight", selectedRow?.detail?.qty_weight || "");
  }
}, [selectedRow, setValue]);


  const fetchInitialData = async () => {
    try {
      const rawNameResponse = await getRawStage();
      setRawName(rawNameResponse);

      const employeeResponse = await getEmployeeName();
      setEmployee(employeeResponse);

      const machineResponse = await getMachineName();
      setMachine(machineResponse);

      const stageResponse = await getStages();
      setStage(stageResponse);
      setCameStage(stageResponse);

      const subStageResponse = await getsubStages();
      setSubStage(subStageResponse);
    } catch (error) {
      toast.error("Failed to fetch initial data");
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const values = getValues();
      const data = {
        id: values.id,
        raw_material_id: values.rawName,
        stage_id: values.stage,
        sub_stage_id: values.subStage,
        came_from_stage_id: -1,
        pcs: values.pcs,
        weight: values.weight,
        employee_id: values.employee,
        machine_id: values.machine,
      };
      await addAssembly(data); 
      reset();
      handleClose();
    } catch (error) {
      toast.error("Failed to save data");
      console.error(error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const values = getValues();
      const data = {
        id: values.id,
        id: selectedRow?.detail?.id,
        raw_material_id: values.rawName,
        stage_id: values.stage,
        sub_stage_id: values.subStage,
        came_from_stage_id: values.CameFromStage,
        pcs: values.pcs,
        weight: values.weight,
        employee_id: values.employee,
        machine_id: values.machine,
      };
      await addAssembly(data);
      reset();
      handleClose(); 
    } catch (error) {
      toast.error("Failed to save data");
      console.error(error);
    }
  };

  const handleClose = () => {
    reset();
    if (onClose) onClose();
  };

  return (
    <div style={{ marginTop: 14 }}>
      <FormProvider
        methods={methods}
        onSubmit={selectedRow?.stage_id ? handleUpdate : handleSave}
      >
        <Typography variant="h5" component="h1">
        {headerTitle}
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4} mt={0}>
            <Grid item xs={4}>
              <RHFSelect name="rawName" label="Select Row Material"  value={methods.watch("rawName")}
              onChange={(e) => methods.setValue("rawName", e.target.value)}
              disabled={selectedRow?.detail}
              required>
                <MenuItem value="">Select an option</MenuItem>
                {rawName?.map((el, index) => (
                  <MenuItem value={el.id} key={index}>
                    {el.raw_material_name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={4}>
              <RHFSelect name="employee" label="Select Employee" required>
                <MenuItem value={""}>Select Emp</MenuItem>
                {employee?.map((el, index) => (
                  <MenuItem value={el.id} key={index}>
                    {el.employee_name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={4}>
              <RHFSelect name="machine" label="Select Machine" required>
                <MenuItem value={""}>Select Emp</MenuItem>
                {machine?.map((el, index) => (
                  <MenuItem value={el.id} key={index}>
                    {el.machine_name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item md={4}>
              <RHFSelect name="stage" label="Select Stage" required>
                {stage?.map((el, index) => (
                  <MenuItem value={el?.id} key={index}>
                    {el?.stage_name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            {!selectedRow?.detail && (
              <Grid item md={4}>
                <RHFSelect name="CameFromStage" label="Came From Stage" value={methods.watch("CameFromStage")}
                  onChange={(e) => methods.setValue("CameFromStage", e.target.value)}
                  disabled={selectedRow?.detail}
                  required>
                  <MenuItem value={-1} disabled={methods.formState.dirty}>
                    -1
                  </MenuItem>

                  {camestage?.map((el, index) => (
                    <MenuItem value={el?.id} key={index}>
                      {el?.stage_name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
            )}
            <Grid item md={4}>
              <RHFSelect name="subStage" label="Select Sub stage" required>
                {subStage?.map((el, index) => (
                  <MenuItem value={el?.id} key={index}>
                    {el?.sub_stage_name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item md={4}>
              <RHFTextField
                name="pcs"
                label="pcs"
                placeholder="pcs"
                type="number"
              />
            </Grid>
            <Grid item md={4}>
              <RHFTextField
                name="weight"
                label="weight"
                placeholder="weight"
                type="number"
              />
            </Grid>
          </Grid>
        </Box>

        <DialogActions sx={{ mt: 5}}>
          <Button
            sx={{
              color: '#fff',
              minWidth: 100,
              backgroundColor: "#3ABEF9",
              "&:hover": {
                backgroundColor: "#3572EF",
              },
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{
              minWidth: 100,
              borderColor: "#3ABEF9",
              "&:hover": {
                borderColor: "#3572EF",
              },
              color: "#3ABEF9",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </div>
  );
};

export default ChangeStageView;
