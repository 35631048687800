import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import { DynamicForm } from "./form/dynamic_form";
import Autocomplete from "@mui/material/Autocomplete";

export default function DynamicSubStage({
  formState,
  formConfig,
  handleCheckBox,
  open,
  fullScreen,
  handleClose,
  handleSave,
  title,
  handleChange,
  handleStage,
  stage,
  subProductNameAlias,
  handleSubNameChange,
  handleSubAliasChange,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSave}>
        <AppBar sx={{ position: "relative", backgroundColor: "#3ABEF9" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#fff" }}
              variant="h7"
              component="div"
            >
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DynamicForm
            formState={formState}
            handleChange={handleChange}
            handleSave={handleSave}
            formConfig={formConfig}
            handleCheckBox={handleCheckBox}
          />
        </DialogContent>
        {/* <DialogContent>
          <Box sx={{ ml: 2, display: "flex", gap: 1 }}>
            {subProductNameAlias?.map((item, index) => (
              <Box key={index} sx={{ display: "flex", gap: 1 }}>
                <Box sx={{ width: 200 }}>
                  <TextField
                    label="Sub Stage Name"
                    name="sub_stage_name"
                    value={item.sub_stage_name}
                    onChange={(e) =>
                      handleSubNameChange(index, e.target.name, e.target.value)
                    }
                    required
                  />
                </Box>
                <Box sx={{ width: 200 }}>
                  <TextField
                    label="Sub Stage Alias"
                    name="sub_stage_alias"
                    value={item.sub_stage_alias}
                    onChange={(e) =>
                      handleSubAliasChange(index, e.target.name, e.target.value)
                    }
                    required
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          <Box sx={{ ml: 2, display: "flex", gap: 1 }}>
            {StageList?.map((item, index) => (
              <Box key={index} sx={{ gap: 2, width: 200 }}>
                <FormControl>
                  <Autocomplete
                    options={stage}
                    getOptionLabel={(option) => option.stage_name}
                    value={stage.find((s) => s.id === item?.stage_id_fk) || null}
                    onChange={(event, newValue) =>
                      handleStage(index, "stage_id_fk", newValue?.id || "")
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Stage Name" />
                    )}
                    required
                  >
                    {stage?.map((e) => (
                      <MenuItem key={e?.id} value={e?.id}>
                        {e?.stage_name}
                      </MenuItem>
                    ))}
                  </Autocomplete>
                </FormControl>
              </Box>
            ))}
          </Box>
        </DialogContent> */}

        <DialogContent>
          <Box sx={{ ml: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            {subProductNameAlias?.map((item, index) => (
              <Box key={index} sx={{ display: "flex", gap: 2 }}>
                <TextField
                  label="Sub Stage Name"
                  name="sub_stage_name"
                  value={item.sub_stage_name}
                  onChange={(e) =>
                    handleSubNameChange(index, e.target.name, e.target.value)
                  }
                  required
                  sx={{ width: 200 }}
                />
                <TextField
                  label="Sub Stage Alias"
                  name="sub_stage_alias"
                  value={item.sub_stage_alias}
                  onChange={(e) =>
                    handleSubAliasChange(index, e.target.name, e.target.value)
                  }
                  required
                  sx={{ width: 200 }}
                />
                <FormControl sx={{ width: 200 }}>
                  <Autocomplete
                    options={stage}
                    getOptionLabel={(option) => option.stage_name}
                    value={stage.find((s) => s.id === item.stage_id_fk) || null}
                    onChange={(event, newValue) =>
                      handleStage(index, "stage_id_fk", newValue?.id || "")
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Stage Name" required />
                    )}
                  />
                </FormControl>
              </Box>
            ))}
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            sx={{
              color: "#fff",
              minWidth: 100,
              backgroundColor: "#3ABEF9",
              "&:hover": {
                backgroundColor: "#3572EF",
              },
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{
              minWidth: 100,
              borderColor: "#3ABEF9",
              "&:hover": {
                borderColor: "#3572EF",
              },
              color: "#3ABEF9",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
