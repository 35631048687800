import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { MdPreview } from "react-icons/md";
import MasterDisplay from "../components/view-master/MasterDisplay";
import { Button, Modal,CircularProgress } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { useDemoData } from "@mui/x-data-grid-generator";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GridToolbar } from "@mui/x-data-grid-premium";
import {
  GridRowModes,
  DataGrid,
  // GridToolbarContainer,
  GridToolbarQuickFilter,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import DynamicFormDialog from "./dynamic_form_dialogue";

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f4f6f8",
            color: "#637381",
            fontSize: 14,
            fontFamily: ["Public Sans, sans-serif"].join(","),
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-withBorderColor": {
            borderRight: 0,
            borderLeft: 0,
          },
          "& .MuiDataGrid-cell": {
            borderColor: "gray",
            borderRight: 0,
            borderLeft: 0,
            color: "#212B36",
            fontSize:14,
            fontWeight: 400,
            fontFamily: ["Public Sans, sans-serif"].join(","),
            borderBottom: "solid 1px rgba(145, 158, 171, 0.24)",
          },
         
        },
      },
    },
  },
});

export default function MasterTable(props) {
  const [rows, setRows] = React.useState(props.data);
  const [rowModesModel, setRowModesModel] = React.useState({});
  // const [updateData, setUpdateData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [updateForm, setUpdateForm] = React.useState({});
  const [openDetail, setOpenDetail] = React.useState(false);
  const [masterData, setMasterData] = React.useState({});
  const [loading, setLoading] = useState(false); 

  const { data: demoData, loading: demoLoading } = useDemoData({
    rowLength: 100,
    editable: true,
  });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    const updateData = rows.filter((row) => row.id === id);
    const newData = updateData[0];
    setUpdateForm(newData);
    setOpen(true);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    props.handleDelete(id);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleOtherDetails = (id) => () => {
    // console.log(id);
    const showData = rows.filter((item) => item.id === id);
    const newData = showData[0];
    setMasterData(newData);
    setOpenDetail(true);
  };

  const columns = [
    ...props.column,

    {
      field: "actions",
      // position="fixed",
      type: "actions",
      headerName: "Actions",
      width: 184,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon sx={{ color: "#673ab7" }} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: "red" }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (props.canView) {
    const len = columns.length;
    const obj = {
      field: "view_details",
      type: "actions",
      headerName: "View Details",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => [
        <Button onClick={handleOtherDetails(id)}>
          <MdPreview size={25} />
        </Button>,
      ],
    };
    columns.splice(len - 1, 0, obj);
  }

  const handleChange = (field, value) => {
    setUpdateForm((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    // console.log("change");
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await props.handleUpdate(e, updateForm);
      setOpen(false);
    } catch (error) {}
  };

  const filteredList = columns.filter((item) => item.hide === true);

  const hiddenCols = filteredList.reduce((acc, item) => {
    acc[item.field] = false;
    return acc;
  }, {});

  return (
    <ThemeProvider theme={theme}>
      <>
        <div>
          <Modal
            open={openDetail}
            // onClose={()=>{setOpenDetail(false)}}
          >
            <React.Fragment>
              <MasterDisplay
                handleClose={() => {
                  setOpenDetail(false);
                }}
                data={masterData}
              />
            </React.Fragment>
          </Modal>
        </div>
        <div>
          <Box>
            <DynamicFormDialog
              open={open}
              title="Edit Data"
              handleClose={handleClose}
              formState={updateForm}
              handleCheckBox={props.handleCheckBox}
              handleChange={handleChange}
              handleSave={handleUpdate}
              formConfig={props.column}
            />
          </Box>
        </div>

        <div>
          <Link to="/Masters">
            {/* <button className="text-white bg-suchi hover:bg-suchi-hover focus:ring-1 focus:ring-purple-300 font-medium rounded-md text-base px-5 py-2 mb-2 mt-6">
            <KeyboardArrowLeft />
          </button> */}
          </Link>
          <div className="mt-4 flex justify-between mx-2">
          <h1  style={{fontSize:"20px",fontWeight:"700",color: "#212B36",fontFamily: ["Public Sans, sans-serif"].join(",") }}>
          {props.title}
            </h1>
            <button
              type="button"
              onClick={props.toggleForm}
              className="text-white bg-suchi hover:bg-suchi-hover focus:ring-1 focus:ring-purple-300 font-medium rounded-md text-base px-5 py-2"
            >
              ADD DATA
            </button>
          </div>
          <Box sx={{ display: "flex", }}>
            <Box
              sx={{
                // height: 500,
                mt: 3,
                width: "100%",
                background: "#fff",
                overflow: "auto", 
                maxHeight: 600,

                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
                flex: 1,
              }}
            >
               {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              <DataGrid
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                loading={demoLoading}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slotProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                slots={{
                  toolbar: (props) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <GridToolbar {...props} />
                      <div>
                        <QuickSearchToolbar {...props} />
                      </div>
                    </div>
                  ),
                }}
                sx={{
                  width: "auto",
                  // "& .MuiDataGrid-columnHeaders": {
                  //   backgroundColor: "#673ab7",
                  //   color: "#fff",
                  //   // fontSize: 16
                  // },
                  // // '.MuiDataGrid-colCell': {
                  // //   '& .centeredHeaderText': {
                  // //     textAlign: 'center',
                  // //   },
                  // // },
                  // "& .MuiDataGrid-columnHeaders .MuiDataGrid-withBorderColor": {
                  //   borderRight: 0.5,
                  //   borderLeft: 0.5,
                  // },
                  // "& .MuiDataGrid-cell": {
                  //   borderColor: "gray",
                  //   borderRight: 0.5,
                  //   borderLeft: 0.5,
                  // },
                }}
              />
              {rows?.length === 0 && (
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontSize: "16px",
                    color: "#637381",
                  }}
                >
                  {/* No data available */}
                </div>
              )}
            </Box>
          </Box>
        </div>
      </>
    </ThemeProvider>
  );
}
