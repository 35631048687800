import React, { useRef, useEffect } from "react";
import { register } from "swiper/element/bundle";

register();

const Imageslider = ({ data, imageType }) => {
  const fileList = data?.file_name?.split(",");
  console.log("ddddd", data);
  console.log("File list in Imageslider:", fileList);

  const swiperElRef = useRef(null);

  useEffect(() => {
    swiperElRef.current.addEventListener("progress", (e) => {
      const [swiper, progress] = e.detail;
      console.log(progress);
    });

    swiperElRef.current.addEventListener("slidechange", (e) => {});
  }, []);

  return (
    <div>
      <swiper-container
        ref={swiperElRef}
        slides-per-view="1"
        navigation="true"
        pagination="true"
        loop="true"
        style={{
          width: 300,
          height: 250,
          backgroundColor: "skyblue",
          /* Navigation arrows styles */
          "--swiper-navigation-color": "black" /* Change arrow color */,
          "--swiper-navigation-size": "25px" /* Adjust arrow size */,
          "--swiper-navigation-bg-color": "blue",
          "--swiper-navigation-padding": "20px",
          "--swiper-navigation-border-radius": "5px",

          /* Pagination dots styles */
          "--swiper-pagination-color": "black" /* Change dot color */,
          "--swiper-pagination-bullet-width": "10px" /* Adjust dot size */,
          "--swiper-pagination-bullet-height": "10px",
          "--swiper-pagination-bullet-border-radius": "50%",
          "--swiper-pagination-bullet-margin": "0 5px",

          /* Active dot color */
          "--swiper-pagination-bullet-active-color": "#C70039",
        }}
      >
        {fileList?.length > 0 &&
          fileList?.map((item, index) => (
            <swiper-slide key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src={`https://suchi.backendapihub.com/images/${imageType}/${item}`}
                alt="pic"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
              />
            </swiper-slide>
          ))}
      </swiper-container>
    </div>
  );
};

export default Imageslider;
