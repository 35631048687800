import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useCallback } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import { Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import { DynamicForm } from "./form/dynamic_form";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import UploadImage from "./form/UploadImage";

export default function DynamicSupplierTable({
  formState,
  formConfig,
  handleCheckBox,
  name,
  open,
  fullScreen,
  handleClose,
  handleSave,
  title,
  handleChange,
  bankList,
  contactList,
  shippingList,
  handleShippingAdd,
  handleShippChange,
  handleContactChange,
  handleBankChange,
  handleAddInput,
  handleAddBank,
  states,
  cities,
  handleClick,
  handleRemoveInput,
  handleRemoveBank,
  clientCompany,
  handleComapnyNameChange,
  handleCompanyAliasChange,
  handleGstChange,
  handleLinkChange,
  handlePanChange,
  handleTanChange,
  errors = {},
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ "& .MuiDialog-paper": { width: "80%", maxWidth: "none" } }}
    >
      <form onSubmit={handleSave}>
        <AppBar sx={{ position: "relative", backgroundColor: "#3ABEF9" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#fff" }}
              variant="h7"
              component="div"
            >
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DynamicForm
            formState={formState}
            handleChange={handleChange}
            handleSave={handleSave}
            formConfig={formConfig}
            handleCheckBox={handleCheckBox}
          />
        </DialogContent>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          <Box
            sx={{
              ml: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            {clientCompany?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                  <Box sx={{ width: "33.33%" }}>
                    <TextField
                      label="Company Name"
                      name="company_name"
                      value={item.company_name}
                      onChange={(e) =>
                        handleComapnyNameChange(
                          index,
                          e.target.name,
                          e.target.value
                        )
                      }
                      fullWidth
                      required
                    />
                  </Box>
                  <Box sx={{ width: "33.33%" }}>
                    <TextField
                      label="Company Alias"
                      name="company_alias"
                      value={item.company_alias}
                      onChange={(e) =>
                        handleCompanyAliasChange(
                          index,
                          e.target.name,
                          e.target.value
                        )
                      }
                      fullWidth
                      required
                    />
                  </Box>
                  <Box sx={{ width: "33.33%" }}>
                    <TextField
                      label="GST Code"
                      name="gstin"
                      value={item.gstin}
                      onChange={(e) =>
                        handleGstChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      error={Boolean(errors[`clientCompany[${index}].gstin`])}
                      helperText={errors[`clientCompany[${index}].gstin`] || ''}
                      required
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
                  <Box sx={{ width: "33.33%" }}>
                    <TextField
                      label="Website Link"
                      name="website_link"
                      value={item.website_link}
                      onChange={(e) =>
                        handleLinkChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      error={Boolean(errors[`clientCompany[${index}].website_link`])}
                      helperText={errors[`clientCompany[${index}].website_link`] || ''}
                      required
                    />
                  </Box>
                  <Box sx={{ width: "33.33%" }}>
                    <TextField
                      label="Pan Number"
                      name="pan_number"
                      value={item.pan_number}
                      onChange={(e) =>
                        handlePanChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      error={Boolean(errors[`clientCompany[${index}].pan_number`])}
                      helperText={errors[`clientCompany[${index}].pan_number`] || ''}
                      required
                    />
                  </Box>
                  <Box sx={{ width: "33.33%" }}>
                    <TextField
                      label= "Tan Number"
                      name= "tan_number"
                       type= "number"
                      value={item.tan_number}
                      onChange={(e) =>
                        handleTanChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      required
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          {formConfig.map((field) => {
            if (field.type === "image") {
              if (!field.imageType) {
                return null;
              }
              return (
                <UploadImage
                  key={field.field}
                  field={field.field}
                  multiple={field.multiple}
                  name={field.headerName}
                  handleChange={handleChange}
                  required={field.required}
                  imageType={field.imageType}
                />
              );
            }
            return null;
          })}
        </DialogContent>
        <DialogContent>
          <Box sx={{ ml: 2, gridColumn: "span 2" }}>
            <span className="block mb-2 text-base font-medium text-gray-900">
              {name} Contact Details:
              <IconButton
                onClick={handleAddInput}
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                <AddCircleOutline />
              </IconButton>
            </span>
            <div>
              {contactList?.map((item, index) => (
                <Box key={index} sx={{ display: "inline-flex", gap: 2, mb: 2 }}>
                  <TextField
                    label="Person Name"
                    name="person_name"
                    value={item.person_name}
                    onChange={(e) =>
                      handleContactChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                  <TextField
                    label="Person Alias"
                    name="person_alias"
                    value={item.person_alias}
                    onChange={(e) =>
                      handleContactChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                  <TextField
                    label="Person Designation"
                    name="person_designation"
                    value={item.person_designation}
                    onChange={(e) =>
                      handleContactChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                  <TextField
                    label="Contact Number"
                    name="contact_number"
                    value={item.contact_number}
                    onChange={(e) =>
                      handleContactChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                  <TextField
                    label="Email"
                    name="email"
                    value={item.email}
                    onChange={(e) =>
                      handleContactChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    error={Boolean(errors[`contactList[${index}].email`])}
                    helperText={errors[`contactList[${index}].email`]}
                    required
                  />
                  {/* {index === contactList.length - 1 && (
                    <IconButton onClick={handleAddInput} sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                      <AddCircleOutline />
                    </IconButton>
                  )} */}
                  {index !== contactList.length - 1 && (
                    <IconButton
                      onClick={() => handleRemoveInput(index)}
                      sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              ))}
            </div>
          </Box>
        </DialogContent>
        <DialogContent>
          <Box sx={{ ml: 2, gridColumn: "span 2" }}>
            <span className="block mb-2 text-base font-medium text-gray-900">
              {name} Bank Details :
              <IconButton
                onClick={handleAddBank}
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                <AddCircleOutline />
              </IconButton>
            </span>
            <div>
              {bankList?.map((item, index) => (
                <Box key={index} sx={{ display: "inline-flex", gap: 2, mb: 2 }}>
                  <TextField
                    label="Bank Name"
                    name="bank_name"
                    value={item.bank_name}
                    onChange={(e) =>
                      handleBankChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                  <TextField
                    label="Account No."
                    name="account_no"
                    value={item.account_no}
                    onChange={(e) =>
                      handleBankChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    error={Boolean(errors[`bankList[${index}].account_no`])}
                    helperText={errors[`bankList[${index}].account_no`]}
                    required
                  />
                  <TextField
                    label="Account Type"
                    name="account_type"
                    value={item.account_type}
                    onChange={(e) =>
                      handleBankChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                  <TextField
                    label="Ifsc Code"
                    name="ifsc_code"
                    value={item.ifsc_code}
                    onChange={(e) =>
                      handleBankChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    error={Boolean(errors[`bankList[${index}].ifsc_code`])}
                    helperText={errors[`bankList[${index}].ifsc_code`]}
                    required
                  />
                  <TextField
                    label="Branch Name"
                    name="branch"
                    value={item.branch}
                    onChange={(e) =>
                      handleBankChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                  {/* {index === bankList.length - 1 && (
                    <IconButton
                      onClick={handleAddBank}
                      sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    >
                      <AddCircleOutline />
                    </IconButton>
                  )} */}
                  {index !== bankList.length - 1 && (
                    <IconButton
                      onClick={() => handleRemoveBank(index)}
                      sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              ))}
            </div>
          </Box>
        </DialogContent>
        <DialogContent>
          <Box sx={{ ml: 2, gridColumn: "span 2" }}>
            <span className="block mb-2 text-base font-medium text-gray-900">
              {name} Shipping Address :
            </span>
            <div>
              {shippingList?.map((item, index) => (
                <Box sx={{ display: "inline-flex", gap: 2 }}>
                  <Box>
                    <TextField
                      label="Address Line 1..."
                      name="address_line_one"
                      value={item.address_line_one}
                      onChange={(e) =>
                        handleShippChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      required
                    />
                    <TextField
                      label="Address Line 2..."
                      name="address_line_two"
                      value={item.address_line_two}
                      onChange={(e) =>
                        handleShippChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      required
                    />
                    <TextField
                      label="Address Line 3..."
                      name="address_line_three"
                      value={item.address_line_three}
                      onChange={(e) =>
                        handleShippChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>

                  <Box>
                    <FormControl fullWidth>
                      <Autocomplete
                        options={states}
                        getOptionLabel={(option) => option.state_name}
                        value={
                          states.find((s) => s.id === item?.state_id) || null
                        }
                        onChange={(event, newValue) =>
                          handleShippChange(
                            index,
                            "state_id",
                            newValue?.id || ""
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                          {...params}
                          label="State"
                          required
                          error={Boolean(
                            errors[`shippingList[${index}].state`]
                          )}
                          helperText={errors[`shippingList[${index}].state`]}
                        />
                      )}
                        fullWidth
                      >
                        {states &&
                          states.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.state_name}
                            </MenuItem>
                          ))}
                      </Autocomplete>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                      <Autocomplete
                        options={cities}
                        getOptionLabel={(option) => option.city_name}
                        value={
                          cities.find((s) => s.id === item?.city_id) || null
                        }
                        onChange={(event, newValue) =>
                          handleShippChange(
                            index,
                            "city_id",
                            newValue?.id || ""
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                          {...params}
                          label="City"
                          required
                          error={Boolean(
                            errors[`shippingList[${index}].city`]
                          )}
                          helperText={errors[`shippingList[${index}].city`]}
                        />
                      )}
                        fullWidth
                      >
                        {cities?.map((e) => (
                          <MenuItem key={e?.id} value={e?.id}>
                            {e?.city_name}
                          </MenuItem>
                        ))}
                      </Autocomplete>
                    </FormControl>

                    <TextField
                      label="State Code"
                      name="state_code"
                      type="number"
                      value={item.state_code}
                      onChange={(e) =>
                        handleShippChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                  {/* {index === shippingList.length - 1 && (
                    <Box display="flex" alignItems="center">
                      <IconButton onClick={() => handleShippingAdd()}>
                        <AddCircleOutline />
                      </IconButton>
                      <Typography>Shipping Address</Typography>
                    </Box>
                  )} */}
                </Box>
              ))}
            </div>
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            sx={{
              color: "#fff",
              minWidth: 100,
              backgroundColor: "#3ABEF9",
              "&:hover": {
                backgroundColor: "#3572EF",
              },
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{ minWidth: 100, borderColor: "#3ABEF9", color: "#3ABEF9" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
