import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { MdPreview } from "react-icons/md";
import MasterDisplay from "../components/view-master/MasterDisplay";
import { Button, Modal, Select, MenuItem,CircularProgress } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { useDemoData } from "@mui/x-data-grid-generator";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  GridToolbar,
} from "@mui/x-data-grid-premium";

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import DynamicFormDialog from "./dynamic_form_dialogue";


const DropdownCell = ({ value }) => (
  <Select
    value=""
    displayEmpty
    renderValue={() => 'Address Details'}
    variant="standard"
    fullWidth
  >
    {value.map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ))}
  </Select>
);

const DropdownCell2 = ({ value }) => (
  <Select
    value=""
    displayEmpty
    renderValue={() => "Contact Details"}
    variant="standard"
  >
    {value.map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ))}
  </Select>
);

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f4f6f8",
            color: "#637381",
            fontSize: 14,
            fontFamily: ["Public Sans, sans-serif"].join(","),
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-withBorderColor": {
            borderRight: 0,
            borderLeft: 0,
          },
          "& .MuiDataGrid-cell": {
            borderColor: "gray",
            borderRight: 0,
            borderLeft: 0,
            color: "#212B36",
            fontSize:14,
            fontWeight: 400,
            fontFamily: ["Public Sans, sans-serif"].join(","),
            borderBottom: "solid 1px rgba(145, 158, 171, 0.24)",
          },
         
        },
      },
    },
  },
});

export default function CompanyDetailTable(props) {
  const [rows, setRows] = React.useState(props?.data);
  const [rowModesModel, setRowModesModel] = React.useState({});
  // const [updateData, setUpdateData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [updateForm, setUpdateForm] = React.useState({});
  const [openDetail, setOpenDetail] = React.useState(false);
  const [masterData, setMasterData] = React.useState({});
  const [loading, setLoading] = useState(false); 

  const { data: demoData, loading: demoLoading } = useDemoData({
    rowLength: 100,
    editable: true,
  });


  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setRows(props?.data);
  }, [props?.data]);

  // console.log("rows====>", rows);
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    const updateData = rows.filter((row) => row.id === id);
    const newData = updateData[0];
    setUpdateForm(newData);
    setOpen(true);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    props.handleDelete(id);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    // console.log("rows---->", rows);
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


const handleOtherDetails = (id) => () => {
  console.log("Current rows data:", rows, id);
  const showData = rows.find((item) => item?.client_details?.id === id);
  const newData = showData?.client_details;
  setMasterData(newData);
  console.log("showData-->", showData);
  setOpenDetail(true);
};


  const columns = [
    // ...props.column,
    {
      field: "company_name",
      headerName: "Company Name",
      width: 200,
      valueGetter: (params) => params.row?.client_details?.company_name,
    },
    {
      field: "company_alias",
      headerName: "Company Alias",
      width: 200,
      valueGetter: (params) => params.row?.client_details?.company_alias,
    },
    {
      field: "person_name",
      headerName: "Person  Name",
      width: 200,
      valueGetter: (params) => params.row?.contact_details.map(contact => contact.person_name).join(", "),
    },
    {
      field: "person_designation",
      headerName: "Person Designation",
      width: 200,
      valueGetter: (params) => params.row?.contact_details.map(contact => contact.person_designation).join(", "),
    },
    // {
    //   field: "contact_number",
    //   headerName: "Contact Number",
    //   width: 200,
    //   valueGetter: (params) => params.row?.contact_details.map(contact => contact.contact_number).join(", "),
    // },
    // {
    //   field: "person_email",
    //   headerName: "Email Id",
    //   width: 200,
    //   valueGetter: (params) => params.row?.contact_details.map(contact => contact.person_email).join(", "),
    // },
    {
      field: "bank_name",
      headerName: "Bank Name",
      width: 200,
      valueGetter: (params) => params.row?.bank_details?.map(bank => bank.bank_name).join(", ") || '',
    },
    {
      field: "account_no",
      headerName: "Account No",
      width: 200,
      valueGetter: (params) => params.row?.bank_details?.map(bank => bank.account_no).join(", ") || '',
    },
    {
      field: "account_type",
      headerName: "Account Type",
      width: 200,
      valueGetter: (params) => params.row?.bank_details?.map(bank => bank.account_type).join(", ") || '',
    },
    {
      field: "ifsc_code",
      headerName: "Ifsc Code",
      width: 200,
      valueGetter: (params) => params.row?.bank_details?.map(bank => bank.ifsc_code).join(", ") || '',
    },
    {
      field: "branch",
      headerName: "Branch Name",
      width: 200,
      valueGetter: (params) => params.row?.bank_details?.map(bank => bank.branch).join(", ") || '',
    },
    {
      field: "gstin",
      headerName: "GST Number",
      width: 200,
      valueGetter: (params) => params.row?.client_details?.gstin,
    },
    {
      field: "website_link",
      headerName: "Website Link",
      width: 200,
      valueGetter: (params) => params.row?.client_details?.website_link,
    },
    {
      field: "pan_number",
      headerName: "Pan Number",
      width: 150,
      valueGetter: (params) => params.row?.client_details?.pan_number,
    },
    {
      field: "tan_number",
      headerName: "Tan Nnumber",
      width: 150,
      valueGetter: (params) => params.row?.client_details?.tan_number,
    },
    {
      field: "company_address", 
      headerName: "Company Address",
      width: 300,
      valueGetter: (params) =>
        params.row?.company_address
          ?.map(
            (address) =>
              `${address.address_line_one}, ${address.address_line_two}, ${address.address_line_three}, ${address.city_name}, ${address.state_name}`
          )
          .join(", "),
    },
    {
      field: "delivery_address", 
      headerName: "Delivery Address",
      width: 300,
      valueGetter: (params) =>
        params.row?.delivery_address
          ?.map(
            (address) =>
              `${address.address_line_one}, ${address.address_line_two}, ${address.address_line_three}, ${address.city_name}, ${address.state_name}`
          )
          .join(", "),
    },
   
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 110,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          // <GridActionsCellItem
          //   icon={<EditIcon sx={{ color: "#673ab7" }} />}
          //   label="Edit"
          //   className="textPrimary"
          //   onClick={handleEditClick(id)}
          //   color="inherit"
          // />,
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: "red" }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<MdPreview size={25} />}
            label="View"
            onClick={handleOtherDetails(id)}
            color="primary"
          />,
        ];
      },
    },
  ];

  // if (props.canView) {
  //   const len = columns.length;
  //   const obj = {
  //     field: "view_details",
  //     type: "actions",
  //     headerName: "View Details",
  //     width: 150,
  //     cellClassName: "actions",
  //     getActions: ({ id }) => [
  //       <Button onClick={handleOtherDetails(id)}>
  //         <MdPreview size={25} />
  //       </Button>,
  //     ],
  //   };
  //   columns.splice(len - 1, 0, obj);
  // }

  const handleChange = (field, value) => {
    setUpdateForm((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    // console.log("change");
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await props.handleUpdate(e, updateForm);
      setOpen(false);
    } catch (error) {}
  };

  const filteredList = columns.filter((item) => item.hide === true);
  const hiddenCols = filteredList.reduce((acc, item) => {
    acc[item.field] = false;
    return acc;
  }, {});
  // console.log('columns',columns);
  return (
    <ThemeProvider theme={theme}>
      <>
        <div>
          <Modal
            open={openDetail}
            onClose={() => {
              setOpenDetail(false);
            }}
          >
            <React.Fragment>
              {masterData && (
                <MasterDisplay
                  handleClose={() => {
                    setOpenDetail(false);
                  }}
                  header="company"
                  data={masterData}
                />
              )}
            </React.Fragment>
          </Modal>
        </div>
        <div>
          <Box>
            <DynamicFormDialog
              open={open}
              title="Edit Data"
              handleClose={handleClose}
              formState={updateForm}
              handleCheckBox={props.handleCheckBox}
              handleChange={handleChange}
              handleSave={handleUpdate}
              formConfig={props.column}
            />
          </Box>
        </div>

        <div>
          <Link to="/Masters">
            {/* <button className="text-white bg-suchi hover:bg-suchi-hover focus:ring-1 focus:ring-purple-300 font-medium rounded-md text-base px-5 py-2 mb-2 mt-6">
            <KeyboardArrowLeft />
          </button> */}
          </Link>
          <div className="mt-4 flex justify-between mx-2">
            <h1  style={{fontSize:"20px",fontWeight:"700", color: "#212B36",fontFamily: ["Public Sans, sans-serif"].join(",") }}>
              {props.title}
            </h1>
            <button
              type="button"
              onClick={props.toggleForm}
              className="text-white bg-suchi hover:bg-suchi-hover focus:ring-1 focus:ring-purple-300 font-medium rounded-md text-base px-5 py-2"
            >
              ADD DATA
            </button>
          </div>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                // height: 500,
                mt: 3,
                width: "100%",
                background: "#fff",
                overflow: "auto", 
                maxHeight: 600,

                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },

                flex: 1,
              }}
            >
               {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              {/* {rows?.length > 0 && ( */}
                <DataGrid
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 20, 50, 100]}
                  loading={demoLoading}
                  getRowId={(row) => row?.client_details?.id}
                  rows={rows}
                  columns={columns}
                  editMode="row"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStart={handleRowEditStart}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  slotProps={{
                    toolbar: { setRows, setRowModesModel },
                  }}
                  slots={{
                    toolbar: (props) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <GridToolbar {...props} />
                        <div>
                          <QuickSearchToolbar {...props} />
                        </div>
                      </div>
                    ),
                  }}
                  sx={{
                    width: "auto",
                    // "& .MuiDataGrid-columnHeaders": {
                    //   backgroundColor: "#673ab7",
                    //   color: "#fff",
                    //   fontSize: 16,
                    // },
                    // ".MuiDataGrid-colCell": {
                    //   "& .centeredHeaderText": {
                    //     textAlign: "center",
                    //   },
                    // },
                    // "& .MuiDataGrid-columnHeaders .MuiDataGrid-withBorderColor": {
                    //   borderRight: 0.5,
                    //   borderLeft: 0.5,
                    // },
                    // "& .MuiDataGrid-cell": {
                    //   borderColor: "gray",
                    //   borderRight: 0.5,
                    //   borderLeft: 0.5,
                    // },
                  }}
                />
              {/* )} */}
              {rows?.length === 0 && (
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontSize: "16px",
                    color: "#637381",
                  }}
                >
                  {/* No data available */}
                </div>
              )}
            </Box>
          </Box>
        </div>
      </>
    </ThemeProvider>
  );
}
