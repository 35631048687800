import React, { useEffect, useState } from "react";
import { getStages } from "../../services/handlers/get_masters";
import { getsubStages } from "../../services/handlers/get_masters";
import { addSubStage } from "../../services/handlers/add_masters";
import { deleteSubStage } from "../../services/handlers/delete_masters";
import { updateSubStage } from "../../services/handlers/update_masters";
import DynamicSubStage from "../../components/Dynamic_SubStage";
import DynamicSubStages from "../../components/Dynamic_SubStages";

const MasterSubStage = () => {
  const formConfig = [
    // {
    //   field: "added_by",
    //   headerName: "Added By",
    //   width: 180,
    // },
    // {
    //   field: "modified_by",
    //   headerName: "Modified By",
    //   width: 180,
    // },
    // {
    //   field: "createdAt",
    //   headerName: "Added Date",
    //   type: "date",
    //   width: 180,
    // },
    // {
    //   field: "updatedAt",
    //   headerName: "Modified Date",
    //   type: "date",
    //   width: 180,
    // },
  ];

  const [data, setData] = useState([]);
  const [loadformConfig, setFormConfig] = useState(formConfig);
  const [reload, setReload] = useState(false);
  const [formState, setFormState] = useState({});
  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState([]);

  const [subProductNameAlias, setsubProductNameAlias] = useState([
    {
      sub_stage_name: "",
      sub_stage_alias: "",
      stage_id_fk: "",
    },
  ]);

  const handleSubNameChange = (index, key, value) => {
    setsubProductNameAlias((prevsubProductNameAlias) => {
      const updatedsubProductNameAlias = [...prevsubProductNameAlias];
      updatedsubProductNameAlias[index][key] = value;
      return updatedsubProductNameAlias;
    });
    handleChange("sub_stage_name", subProductNameAlias);
  };

  const handleSubAliasChange = (index, key, value) => {
    setsubProductNameAlias((prevsubProductNameAlias) => {
      const updatedsubProductNameAlias = [...prevsubProductNameAlias];
      updatedsubProductNameAlias[index][key] = value;
      return updatedsubProductNameAlias;
    });
    handleChange("sub_stage_alias", subProductNameAlias);
  };

  const handleStage = (index, key, value) => {
    setsubProductNameAlias((prevsubProductNameAlias) => {
      const updatedsubProductNameAlias = [...prevsubProductNameAlias];
      updatedsubProductNameAlias[index][key] = value;

      return updatedsubProductNameAlias;
    });
    handleChange("stage_id_fk", subProductNameAlias);
  };

  const handleChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    fetchData();
  }, [reload]);

  async function fetchData() {
    const response = await getsubStages();
    setData(response);
  }

  useEffect(() => {
    const fetchStage = async () => {
      try {
        const data = await getStages();
        setStage(data);
      } catch (error) {}
    };

    fetchStage();
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const formattedFormState = {
        ...formState,
        stage_id_fk: formState.stage_id_fk.map((item) => item.stage_id_fk),
        sub_stage_name: formState.sub_stage_name.map(
          (item) => item.sub_stage_name
        ),
        sub_stage_alias: formState.sub_stage_alias.map(
          (item) => item.sub_stage_alias
        ),
      };
      await addSubStage(formattedFormState);
      resetForms();
      toggleForm();
      setReload(!reload);
    } catch (error) {
      console.log(error);
    }
  };
  const resetForms = () => {
    setsubProductNameAlias([
      {
        sub_stage_name: "",
        sub_stage_alias: "",
        stage_id_fk: "",
      },
    ]);
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteSubStage(id);
    } catch (error) {}
  };

  const handleUpdate = async (e, data) => {
    e.preventDefault();
    console.log("update", data);
    const response = await updateSubStage(data);
    console.log("rest update", response);
    setReload(!reload);
  };

  const toggleForm = () => {
    resetForms();
    setOpen(!open);
    setFormState({});
  };

  return (
    <div style={{ width: "78%", position: "fixed" }}>
      <DynamicSubStages
        title="Sub Stages"
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
        toggleForm={toggleForm}
        column={loadformConfig}
        formState={formState}
        data={data}
      />
      <DynamicSubStage
        title="Add Sub Stage"
        open={open}
        handleClose={toggleForm}
        formState={formState}
        handleChange={handleChange}
        handleSave={handleSave}
        formConfig={loadformConfig}
        handleStage={handleStage}
        stage={stage}
        subProductNameAlias={subProductNameAlias}
        handleSubNameChange={handleSubNameChange}
        handleSubAliasChange={handleSubAliasChange}
      />
    </div>
  );
};

export default MasterSubStage;
