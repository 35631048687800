import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useCallback } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import { Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import { DynamicForm } from "./form/dynamic_form";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import UploadImage from "./form/UploadImage";

export default function DynamicSubProductDetails({
  formState,
  formConfig,
  handleCheckBox,
  name,
  open,
  fullScreen,
  handleClose,
  handleSave,
  title,
  handleChange,
  subProductList,
  ProductList,
  ProductNameAlias,
  handleAliasChange,
  handleNameChange,
  TaxList,
  handleSubProduct,
  handleAddSubProduct,
  subproduct,
  product,
  handleProduct,
  handleTax,
  tax,
  productErrors,
  taxErrors,
  subProductErrors,
  handleSubPeoductRemove,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ "& .MuiDialog-paper": { width: "50%", maxWidth: "none" } }}
    >
      <form onSubmit={handleSave}>
        <AppBar sx={{ position: "relative", backgroundColor: "#3ABEF9" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#fff" }}
              variant="h7"
              component="div"
            >
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          <DynamicForm
            formState={formState}
            handleChange={handleChange}
            handleSave={handleSave}
            formConfig={formConfig}
            handleCheckBox={handleCheckBox}
          />
        </DialogContent>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          <Box sx={{ ml: 2, display: "flex", gap: 1 }}>
            {ProductNameAlias?.map((item, index) => (
              <Box key={index} sx={{ display: "flex", gap: 1, width: "100%" }}>
                <Box sx={{ width: "50%" }}>
                  <TextField
                    label="Product Name"
                    name="product_name"
                    value={item.product_name}
                    onChange={(e) =>
                      handleNameChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <TextField
                    label="Product Alias"
                    name="product_alias"
                    value={item.product_alias}
                    onChange={(e) =>
                      handleAliasChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          <Box sx={{ ml: 2, display: "flex", gap: 1 }}>
            {ProductList?.map((item, index) => (
              <Box key={index} sx={{ width: "50%" }}>
                <FormControl fullWidth >
                  <Autocomplete
                    options={product}
                    getOptionLabel={(option) => option.category_name}
                    value={
                      product.find((s) => s.id === item?.product_category_id) ||
                      null
                    }
                    onChange={(event, newValue) =>
                      handleProduct(
                        index,
                        "product_category_id",
                        newValue?.id || ""
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Product Category"
                        error={productErrors[index]}
                        helperText={
                          productErrors[index] ? "This field is required" : ""
                        }
                        required
                      />
                    )}
                    fullWidth
                    
                    required
                  >
                    {product?.map((e) => (
                      <MenuItem key={e?.id} value={e?.id}>
                        {e?.category_name}
                      </MenuItem>
                    ))}
                  </Autocomplete>
                </FormControl>
              </Box>
            ))}
            {TaxList?.map((item, index) => (
              <Box key={index} sx={{ gap: 1, width: "50%" }}>
                <FormControl fullWidth >
                  <Autocomplete
                    options={tax}
                    getOptionLabel={(option) => option.tax_percentage}
                    value={
                      tax.find((s) => s.id === item?.tax_percentage_id) || null
                    }
                    onChange={(event, newValue) =>
                      handleTax(index, "tax_percentage_id", newValue?.id || "")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tax Percentage"
                        error={taxErrors[index]}
                        helperText={
                          taxErrors[index] ? "This field is required" : ""
                        }
                        required
                      />
                    )}
                    fullWidth
                    
                    required
                  >
                    {tax?.map((e) => (
                      <MenuItem key={e?.id} value={e?.id}>
                        {e?.tax_percentage}
                      </MenuItem>
                    ))}
                  </Autocomplete>
                </FormControl>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          {formConfig.map((field) => {
            if (field.type === "image") {
              if (!field.imageType) {
                return null;
              }
              return (
                <UploadImage
                  key={field.field}
                  field={field.field}
                  multiple={field.multiple}
                  name={field.headerName}
                  handleChange={handleChange}
                  required={field.required}
                  imageType={field.imageType}
                />
              );
            }
            return null;
          })}
        </DialogContent>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          <Box sx={{ ml: 2, gridColumn: "span 2", }}>
            <span className="block mb-2 text-base font-medium text-gray-900">
              {name} Sub Product :
              <IconButton
                onClick={handleAddSubProduct}
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                <AddCircleOutline />
              </IconButton>
            </span>
            <div>
              {subProductList?.map((item, index) => (
                <Box key={index} sx={{ display: "flex", gap: 1, mb: 1 }}>
                  <FormControl sx={{width:"50%"}} >
                    <Autocomplete
                      options={subproduct}
                      getOptionLabel={(option) => option.product_name}
                      value={
                        subproduct.find((s) => s.id === item?.sub_product_id) ||
                        null
                      }
                      onChange={(event, newValue) =>
                        handleSubProduct(
                          index,
                          "sub_product_id",
                          newValue?.id || ""
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub Product"
                          error={subProductErrors[index]}
                          helperText={
                            subProductErrors[index]
                              ? "This field is required"
                              : ""
                          }
                          required
                        />
                      )}
                      fullWidth 
                      required
                    >
                      {subproduct?.map((e) => (
                        <MenuItem key={e?.id} value={e?.id}>
                          {e?.product_name}
                        </MenuItem>
                      ))}
                    </Autocomplete>
                  </FormControl>

                  {index !== subProductList.length - 1 && (
                    <IconButton
                      onClick={() => handleSubPeoductRemove(index)}
                      sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              ))}
            </div>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            sx={{
              color: "#fff",
              minWidth: 100,
              backgroundColor: "#3ABEF9",
              "&:hover": {
                backgroundColor: "#3572EF",
              },
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{
              minWidth: 100,
              borderColor: "#3ABEF9",
              "&:hover": {
                borderColor: "#3572EF",
              },
              color: "#3ABEF9",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
