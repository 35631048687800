import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DynamicForm } from "./form/dynamic_form";
import { ContactForm } from "./form/contact_form";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

export default function DynamicFormDialog({
  open,
  handleClose,
  title,
  formState,
  handleChange,
  handleSave,
  formConfig,
  fullScreen,
  handleCheckBox,
}) {
  return (
    <Dialog  open={open} onClose={handleClose}>
      <form onSubmit={handleSave}>
        <AppBar sx={{ position: "relative",backgroundColor:'#3ABEF9' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 ,color: '#fff', }} variant="h7" component="div">
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <DynamicForm
            formState={formState}
            handleChange={handleChange}
            handleSave={handleSave}
            formConfig={formConfig}
            handleCheckBox={handleCheckBox}
          />
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            sx={{
              color: '#fff',
              minWidth: 100,
              backgroundColor: "#3ABEF9",
              "&:hover": {
                backgroundColor: "#3572EF",
              },
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{ minWidth: 100,borderColor:"#3ABEF9", "&:hover": {
              borderColor: "#3572EF",
            }, color:"#3ABEF9" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
