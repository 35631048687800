import React, { useEffect, useState } from "react";
import DynamicFormDialog from "../../components/dynamic_form_dialogue";
import MasterTable from "../../components/master_table";
import { addRawMaterialMaster } from "../../services/handlers/add_masters";
import { getRawMaterials } from "../../services/handlers/get_masters";
import { deleteRawMaterial } from "../../services/handlers/delete_masters";
import { updateRawMaterial } from "../../services/handlers/update_masters";
import {
  getCategorie,
  getDimension,
  getDimensionUnit,
  getTaxx,
} from "../../services/handlers/get_dropdowns";
import { Fullscreen } from "@mui/icons-material";
import DynamicDimension from "../../components/Dynamic_Dimension";
import DynamicRawMaterial from "../../components/Dynamic_RawMaterial";

export const MasterRawMaterialMaster = () => {
  const formConfig = [
    {
      field: "is_consumable",
      hide: true,
      headerName: "is Consumable",
      type: "checkbox",
      headerAlign: "start",
      width: 0,
      editable: true,
      active: true,
    },
  ];
  

  const [loadformConfig, setFormConfig] = useState(formConfig);
  const [raw, setRaw] = useState([]);
  const [tax, setTax] = useState([]);
  const [formState, setFormState] = useState({});
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [dimensionsUnit, setDimensionsUnit] = useState([]);

  const [dimensionList, setDimensionList] = useState([
    {
      dimension_id: "",
      dimension_unit_id: "",
    },
  ]);

  const [RawList, setRawList] = useState([
    {
      raw_material_category_id: "",
    },
  ]);
  const [TaxList, setTaxList] = useState([
    {
      tax_percentage_id: "",
    },
  ]);

  const [RawNameAlias, setRawNameAlias] = useState([
    {
      raw_material_name: "",
      raw_material_alias: "",
    },
  ]);

  const handleRawNameChange = (index, key, value) => {
    setRawNameAlias((prevRawNameAlias) => {
      const updatedRawNameAlias = [...prevRawNameAlias];
      updatedRawNameAlias[index][key] = value;
      return updatedRawNameAlias;
    });
    handleChange("raw_material_name", RawNameAlias);
  };

  const handleARawliasChange = (index, key, value) => {
    setRawNameAlias((prevRawNameAlias) => {
      const updatedRawNameAlias = [...prevRawNameAlias];
      updatedRawNameAlias[index][key] = value;
      return updatedRawNameAlias;
    });
    handleChange("raw_material_alias", RawNameAlias);
  };

  const handleRaw = (index, key, value) => {
    setRawList((prevRawList) => {
      const updatedRawList = [...prevRawList];
      updatedRawList[index][key] = value;

      return updatedRawList;
    });
    handleChange("raw_material_category_id", RawList);
  };

  const handleTax = (index, key, value) => {
    setTaxList((prevTaxList) => {
      const updatedTaxList = [...prevTaxList];
      updatedTaxList[index][key] = value;

      return updatedTaxList;
    });
    handleChange("tax_percentage_id", TaxList);
  };

  const handleAddDimension = () => {
    setDimensionList([
      ...dimensionList,
      {
        dimension_id: "",
        dimension_unit_id: "",
      },
    ]);
  };
  const handledimensionListRemove = (index) => {
    const newList = dimensionList.filter((_, i) => i !== index);
    setDimensionList(newList);
  };

  const handleDimensionChange = (index, key, value) => {
    setDimensionList((prevdimensionList) => {
      const updateddimensionList = [...prevdimensionList];
      updateddimensionList[index][key] = value;

      return updateddimensionList;
    });
    handleChange("dimension", dimensionList);
  };

  const handleChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    // console.log(formState);
  };

  useEffect(() => {
    fetch();
  }, [reload]);

  async function fetch() {
    const response = await getRawMaterials();
    setData(response);
    console.log("ress", response);
  }

  useEffect(() => {
    const fetchDimension = async () => {
      try {
        const data = await getDimension();
        setDimensions(data);
        console.log("Ddata--->", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDimension();
  }, []);

  useEffect(() => {
    const fetchDimensionUnit = async () => {
      try {
        const data = await getDimensionUnit();
        setDimensionsUnit(data);
        console.log("data--->", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDimensionUnit();
  }, []);

  useEffect(() => {
    const fetchRaw = async () => {
      try {
        const data = await getCategorie();
        setRaw(data);
      } catch (error) {}
    };

    fetchRaw();
  }, []);

  useEffect(() => {
    const fetchTax = async () => {
      try {
        const data = await getTaxx();
        setTax(data);
      } catch (error) {}
    };

    fetchTax();
  }, []);

  useEffect(() => {
    console.log('Form state:', formState);
  }, [formState]);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const formattedFormState = {
        ...formState,
        raw_material_name: formState.raw_material_name.map(
          (item) => item.raw_material_name
        ),
        raw_material_alias: formState.raw_material_alias.map(
          (item) => item.raw_material_alias
        ),
        raw_material_category_id: formState.raw_material_category_id.map(
          (item) => item.raw_material_category_id
        ),
        tax_percentage_id: formState.tax_percentage_id.map(
          (item) => item.tax_percentage_id
        ),
      };
      await addRawMaterialMaster(formattedFormState);
      resetForms();
      toggleForm();
      setReload(!reload);
    } catch (error) {
      console.log(error);
    }
  };
  const resetForms = () => {
    setDimensionList([
      {
        dimension_id: "",
        dimension_unit_id: "",
      },
    ]);
    setRawList([
      {
        raw_material_category_id: "",
      },
    ]);
    setTaxList([
      {
        tax_percentage_id: "",
      },
    ]);
    setRawNameAlias([
      {
        raw_material_name: "",
        raw_material_alias: "",
      },
    ]);
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteRawMaterial(id);
      // console.log("response",response);
      setReload(!reload);
    } catch (error) {}
  };

  const handleUpdate = async (e, data) => {
    e.preventDefault();
    // console.log("update", data);
    data = { ...data, ...formState };
    const response = await updateRawMaterial(data);
    console.log("rest update", response);
    setReload(!reload);
  };

  const toggleForm = () => {
    resetForms();
    setOpen(!open);
    setFormState({});
  };

  const handleCheckBox = (value) => {
    console.log(value, "check");

    setFormState((prevState) => ({
      ...prevState,
      is_consumable: value,
    }));

    console.log("after click", formState);
  };

  return (
    <div style={{ width: "78%", position: "fixed" }}>
      <DynamicRawMaterial
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
        toggleForm={toggleForm}
        column={formConfig}
        handleCheckBox={handleCheckBox}
        formState={formState}
        canView={true}
        data={data}
        title="Raw Material"
      />
      <DynamicDimension
        title="Add Raw Material"
        open={open}
        handleClose={toggleForm}
        formState={formState}
        dimensionList={dimensionList}
        handleChange={handleChange}
        handleDimensionChange={handleDimensionChange}
        handleAddDimension={handleAddDimension}
        handledimensionListRemove={handledimensionListRemove}
        handleCheckBox={handleCheckBox}
        TaxList={TaxList}
        RawList={RawList}
        handleRawNameChange={handleRawNameChange}
        handleARawliasChange={handleARawliasChange}
        RawNameAlias={RawNameAlias}
        tax={tax}
        raw={raw}
        handleRaw={handleRaw}
        handleTax={handleTax}
        handleSave={handleSave}
        dimensions={dimensions}
        dimensionsUnit={dimensionsUnit}
        formConfig={loadformConfig}
        // fullScreen={true}
      />
    </div>
  );
};
