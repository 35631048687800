import { toast } from "react-toastify";
import apiHandler from "../apiHandler";
import { logDOM } from "@testing-library/react";

export async function addClientDetails(data) {
  data = { ...data, is_active: true };
  try {
    const response = await apiHandler("POST", "/client/add", data);
    toast.success("Client Details added successfully");
    return response;
  } catch (error) {
    toast.error("Error adding client data");
  }
  return [];
}

export async function addCompany(data) {
  data = { ...data, is_active: true };
  try {
    const response = await apiHandler("POST", "/my-company/add", data);
    toast.success("Company added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}

export async function addEmployee(data) {
  data = { ...data, is_active: true };
  try {
    const response = await apiHandler("POST", "/employee/add", data);
    toast.success("Employee added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}

export async function addSupplierImage(filename) {

   try {
    const { data } =  await apiHandler("POST", "/upload/supplier", filename, {
      "Content-Type": "multipart/form-data",
    });
    const imagePath = data?.filename;
    if (imagePath) {
      console.log("imagePath--->", imagePath);

      toast.success("File uploaded Successfully");
      return imagePath;
    }

    throw new Error("Invalid response from server");
  } catch (error) {
    console.error("Cannot add files:", error);
    toast.error("Cannot add files");
    return null;
  }
}

export async function addClientImage(filename) {

   try {
    const { data } =  await apiHandler("POST", "/upload/client", filename, {
      "Content-Type": "multipart/form-data",
    });
    const imagePath = data?.filename;
    if (imagePath) {
      console.log("imagePath--->", imagePath);

      toast.success("File uploaded Successfully");
      return imagePath;
    }

    throw new Error("Invalid response from server");
  } catch (error) {
    console.error("Cannot add files:", error);
    toast.error("Cannot add files");
    return null;
  }
}

export async function addCompanyImage(filename) {

   try {
    const { data } =  await apiHandler("POST", "/upload/company", filename, {
      "Content-Type": "multipart/form-data",
    });
    const imagePath = data?.filename;
    if (imagePath) {
      console.log("imagePath--->", imagePath);

      toast.success("File uploaded Successfully");
      return imagePath;
    }

    throw new Error("Invalid response from server");
  } catch (error) {
    console.error("Cannot add files:", error);
    toast.error("Cannot add files");
    return null;
  }
}

export async function addMachineImage(filename) {

   try {
    const { data } =  await apiHandler("POST", "/upload/machine", filename, {
      "Content-Type": "multipart/form-data",
    });
    const imagePath = data?.filename;
    if (imagePath) {
      console.log("imagePath--->", imagePath);

      toast.success("File uploaded Successfully");
      return imagePath;
    }

    throw new Error("Invalid response from server");
  } catch (error) {
    console.error("Cannot add files:", error);
    toast.error("Cannot add files");
    return null;
  }
}

export async function addEmployeeImage(filename) {

   try {
    const { data } =  await apiHandler("POST", "/upload/employee", filename, {
      "Content-Type": "multipart/form-data",
    });
    const imagePath = data?.filename;
    if (imagePath) {
      console.log("imagePath--->", imagePath);

      toast.success("File uploaded Successfully");
      return imagePath;
    }

    throw new Error("Invalid response from server");
  } catch (error) {
    console.error("Cannot add files:", error);
    toast.error("Cannot add files");
    return null;
  }
}

export async function addRawMaterialImage(filename) {

   try {
    const { data } =  await apiHandler("POST", "/upload/raw-material", filename, {
      "Content-Type": "multipart/form-data",
    });
    const imagePath = data?.filename;
    if (imagePath) {
      console.log("imagePath--->", imagePath);

      toast.success("File uploaded Successfully");
      return imagePath;
    }

    throw new Error("Invalid response from server");
  } catch (error) {
    console.error("Cannot add files:", error);
    toast.error("Cannot add files");
    return null;
  }
}

export async function addSubProductImage(filename) {

   try {
    const { data }  = await apiHandler("POST", "/upload/sub-product", filename, {
      "Content-Type": "multipart/form-data",
    });
    const imagePath = data?.filename;
    if (imagePath) {
      console.log("imagePath--->", imagePath);

      toast.success("File uploaded Successfully");
      return imagePath;
    }

    throw new Error("Invalid response from server");
  } catch (error) {
    console.error("Cannot add files:", error);
    toast.error("Cannot add files");
    return null;
  }
}
// export async function addProductImage(filename) {

//    try {
//     const response = await apiHandler("POST", "/upload/product", filename, {
//       "Content-Type": "multipart/form-data",
//     });
    
//     toast.success("Files uploaded successfully!");
//     return response;
//   } catch (error) {
//     toast.error("Cannot add Files");
//   }
//   return [];
// }

export async function addProductImage(filename) {
  try {
    const { data } = await apiHandler("POST", "/upload/product", filename, {
      "Content-Type": "multipart/form-data",
    });

    const imagePath = data?.filename;
    if (imagePath) {
      console.log("imagePath--->", imagePath);

      toast.success("File uploaded Successfully");
      return imagePath;
    }

    throw new Error("Invalid response from server");
  } catch (error) {
    console.error("Cannot add files:", error);
    toast.error("Cannot add files");
    return null;
  }
}

export async function addSupplier(data) {
  data = { ...data, is_active: true };
  try {
    const response = await apiHandler("POST", "/supplier/add", data);
    toast.success("Supplier added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}

export async function addDimensions(data) {
  try {
    const response = await apiHandler(
      "POST",
      "/master/dimension-master/add",
      data
    );
    toast.success("Dimension added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}

export async function addProductCategory(data) {
  try {
    const response = await apiHandler(
      "POST",
      "/masters/product_category_masters",
      data
    );
    toast.success("Category added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}
export async function addSubProduct(data) {
  try {
    const response = await apiHandler(
      "POST",
      "/sub-product/add",
      data
    );
    toast.success("Sub Product added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}
export async function addProduct(data) {
  try {
    const response = await apiHandler(
      "POST",
      "/masters/product/add",
      data
    );
    toast.success("Product added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}

export async function addDimensionUnits(data) {
  try {
    const response = await apiHandler(
      "POST",
      "/master/dimension-unit-master/add",
      data
    );
    toast.success("Dimension Unit added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}

export async function addContact(data) {
  data = { ...data, is_active: true };
  try {
    const response = await apiHandler("POST", "/client/add", data);
    toast.success("Contact added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}

export async function addRawMaterialCategory(data) {
  data = { ...data, is_active: true };
  try {
    const response = await apiHandler(
      "POST",
      "/master/raw-material/categories/add",
      data
    );
    toast.success("Raw Material added successfully");
    return response;
  } catch (error) {
    toast.error("Raw Material add data");
  }
  return [];
}

export async function addRawMaterialMaster(data) {
  data = { ...data, };
  try {
    const response = await apiHandler("POST", "/raw-material/add", data);
    toast.success("Raw Material added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}

export async function addStage(data) {
  data = { ...data, is_active: true };
  try {
    const response = await apiHandler("POST", "/masters/stages", data);
    toast.success("Stage added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}

export async function addSubStage(data) {
  data = { ...data, is_active: true };
  try {
    const response = await apiHandler("POST", "/masters/sub_stages", data);
    toast.success("Sub Stage added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}

export async function addMachine(data) {
  data = { ...data, is_active: true };
  try {
    const response = await apiHandler("POST", "/master/machine/add", data);
    toast.success("Machine added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}

export async function addAssembly(data) {
  try {
    const response = await apiHandler(
      "POST",
      "/assembly/add",
      data
    );
    toast.success("added successfully");
    return response;
  } catch (error) {
    toast.error("Cannot add data");
  }
  return [];
}



