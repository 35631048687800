import React, { useState,useEffect } from 'react'

export const CheckForm = (props) => {
  // console.log("vak",props.value)
  const [isChecked, setIsChecked] = useState(props.value === 1);

  useEffect(() => {
    setIsChecked(props.value === 1);
  }, [props.value]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    props.handleCheckBox(checked ? '1' : '0'); 
  };

  return (
    <div className='ml-5' style={{alignSelf:'flex-end'}}>
      
        <input type="checkbox" name="address" id="" checked={isChecked} onChange={handleChange}
        // value={isChecked}
        //   onChange={(e) => {
        //     console.log(e.target.checked)
        //     setIsChecked(!isChecked)
            

        //       props.handleCheckBox(e.target.checked)
            
            
        //   }}
        
          style={{marginLeft:2}}
        />
        <label htmlFor="Address" style={{marginLeft:5}}>
          {props.name}
           </label>
        
    </div>
  )
}
