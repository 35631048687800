import React, { useEffect, useState } from "react";
import { getCompany } from "../../services/handlers/get_masters";
import { addCompany } from "../../services/handlers/add_masters";
import { addImage } from "../../services/handlers/add_masters";
import { deleteCompany } from "../../services/handlers/delete_masters";
import { updateCompany } from "../../services/handlers/update_masters";
import { Hidden } from "@mui/material";
import DynamicContact from "../../components/Dynamic_contact";
import { getState, getCities } from "../../services/handlers/get_dropdowns";
import { toast } from "react-toastify";
import validator from "validator";
import CompanyDetailTable from "../../components/CompanyDetail_Table";

function isValidGSTNo(str) {
  let regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  return regex.test(str);
}

// const validateURL = (value) => {
//   return validator.isURL(value);
// };

function isValidPanCardNo(panCardNo) {
  let regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  return regex.test(panCardNo);
}

function isValidPhoneNumber(phoneNumber) {
  const pattern = /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;
  return pattern.test(phoneNumber);
}

function isValidEmail(email) {
  const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return pattern.test(email);
}

function isValid_Bank_Acc_Number(bank_account_number) {
  let regex = /^[0-9]{9,18}$/;
  return bank_account_number != null && regex.test(bank_account_number);
}

function isValidIFSCCode(ifsc_Code) {
  let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
  return ifsc_Code != null && regex.test(ifsc_Code);
}

const validateURL = (value) => {
  if (Array.isArray(value)) {
    return value.every((v) => typeof v === 'string' && validator.isURL(v));
  } else {
    return typeof value === 'string' && validator.isURL(value);
  }
};

export const MasterCompany = () => {
  const formConfig = [
    // {
    //   field: "company_name",
    //   headerName: "Company Name",
    //   flex: 0.5,
    //   minWidth: 200,
    //   active: true,
    //   required: true,
    //   valueGetter: (params) => params.row?.client_details?.company_name,
    // },
    // {
    //   field: "company_alias",
    //   headerName: "Company Alias",
    //   width: 200,
    //   active: true,
    //   required: true,
    //   valueGetter: (params) => params.row?.client_details?.company_alias,
    // },
    // {
    //   field: "gstin",
    //   headerName: "GST Code",
    //   width: 200,
    //   active: true,
    //   required: true,
    //   valueGetter: (params) => params.row?.client_details?.gstin,
    //   validator: isValidGSTNo,
    // },
    // {
    //   field: "website_link",
    //   headerName: "Website Link",
    //   width: 200,
    //   active: true,
    //   required: true,
    //   valueGetter: (params) => params.row?.client_details?.website_link,
    //   validator: validateURL,
    // },
    // {
    //   field: "pan_number",
    //   headerName: "Pan Number",
    //   width: 200,
    //   active: true,
    //   required: true,
    //   valueGetter: (params) => params.row?.client_details?.pan_number,
    //   validator: isValidPanCardNo,
    // },
    // {
    //   field: "tan_number",
    //   headerName: "Tan Number",
    //   width: 200,
    //   type: "number",
    //   active: true,
    //   valueGetter: (params) => params.row?.client_details?.tan_number,
    // },
    {
      field: "file_name",
      hide: true,
      headerName: "file",
      multiple: false,
      type: "image",
      imageType: "company",
      width: 200,
    }
  ];

  const [errors, setErrors] = useState({});
  const [loadformConfig, setFormConfig] = useState(formConfig);
  const [formState, setFormState] = useState({});
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [contactList, setContactList] = useState([
    {
      person_name: "",
      person_alias: "",
      person_designation: "",
      contact_number: "",
      email: "",
    },
  ]);
  console.log("formstate--->", formState);
  const handleAddInput = () => {
    setContactList([
      ...contactList,
      {
        person_name: "",
        person_alias: "",
        person_designation: "",
        contact_number: "",
        email: "",
      },
    ]);
  };

  const handleRemoveInput = (index) => {
    const newList = contactList.filter((_, i) => i !== index);
    setContactList(newList);
  };
  
  const [bankList, setBankList] = useState([
    {
      bank_name: "",
      account_no: "",
      account_type: "",
      ifsc_code: "",
      branch: "",
    },
  ]);

  const handleAddBank = () => {
    setBankList([
      ...bankList,
      {
        bank_name: "",
        account_no: "",
        account_type: "",
        ifsc_code: "",
        branch: "",
      },
    ]);
  };
  const handleRemoveBank = (index) => {
    const newList = bankList.filter((_, i) => i !== index);
    setBankList(newList);
  };
  const [clientCompany, setclientCompanys] = useState([
    {
      company_name: "",
      company_alias: "",
      gstin: "",
      website_link: "",
      pan_number: "",
      tan_number: "",
    },
  ]);

  const handleComapnyNameChange = (index, key, value) => {
    setclientCompanys((prevclientCompany) => {
      const updatedclientCompany = [...prevclientCompany];
      updatedclientCompany[index][key] = value;
      return updatedclientCompany;
    });
    handleChange("company_name", clientCompany);
  };

  const handleCompanyAliasChange = (index, key, value) => {
    setclientCompanys((prevclientCompany) => {
      const updatedclientCompany = [...prevclientCompany];
      updatedclientCompany[index][key] = value;
      return updatedclientCompany;
    });
    handleChange("company_alias", clientCompany);
  };
  const handleGstChange = (index, key, value) => {
    setclientCompanys((prevclientCompany) => {
      const updatedclientCompany = [...prevclientCompany];
      updatedclientCompany[index][key] = value;
      return updatedclientCompany;
    });
    handleChange("gstin", clientCompany);
  };
  const handleLinkChange = (index, key, value) => {
    setclientCompanys((prevclientCompany) => {
      const updatedclientCompany = [...prevclientCompany];
      updatedclientCompany[index][key] = value;
      return updatedclientCompany;
    });
    handleChange("website_link", clientCompany);
  };
  const handlePanChange = (index, key, value) => {
    setclientCompanys((prevclientCompany) => {
      const updatedclientCompany = [...prevclientCompany];
      updatedclientCompany[index][key] = value;
      return updatedclientCompany;
    });
    handleChange("pan_number", clientCompany);
  };
  const handleTanChange = (index, key, value) => {
    setclientCompanys((prevclientCompany) => {
      const updatedclientCompany = [...prevclientCompany];
      updatedclientCompany[index][key] = value;
      return updatedclientCompany;
    });
    handleChange("tan_number", clientCompany);
  };

  const [deliveryList, setDeliveryList] = useState([
    {
      address_line_one: "",
      address_line_two: "",
      address_line_three: "",
      city_id: "",
      state_id: "",
      state_code: "",
    },
  ]);
  const handleDeliveryaddAddress = () => {
    setDeliveryList([
      ...deliveryList,
      {
        address_line_one: "",
        address_line_two: "",
        address_line_three: "",
        city_id: "",
        state_id: "",
        state_code: "",
      },
    ]);
  };
  const handleDeliveryRemoveAddress = (index) => {
    const newList = deliveryList.filter((_, i) => i !== index);
    setDeliveryList(newList);
  };

  const [shippingList, setShippingList] = useState([
    {
      address_line_one: "",
      address_line_two: "",
      address_line_three: "",
      city_id: "",
      state_id: "",
      state_code: "",
    },
  ]);
  // const handleShippingAdd = () => {
  //   setShippingList([
  //     ...shippingList,
  //     {
  //       address_line_one: "",
  //       address_line_two: "",
  //       address_line_three: "",
  //       city_id: "",
  //       state_id: "",
  //       state_code: "",
  //     },
  //   ]);
  // };

  const handleClick = () => {
    if (formState.state_id === "") {
      toast.warning("Please select State first");
    }
  };

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const data = await getState();
        setStates(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const data = await getCities();
        setCities(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCities();
  }, []);

  const handleDelivChange = (index, key, value) => {
    setDeliveryList((prevdeliveryList) => {
      const updateddeliveryList = [...prevdeliveryList];
      updateddeliveryList[index][key] = value;
      return updateddeliveryList;
    });
    handleChange("delivery_address", deliveryList);
  };

  const handleShippChange = (index, key, value) => {
    setShippingList((prevshippingList) => {
      const updatedshippingList = [...prevshippingList];
      updatedshippingList[index][key] = value;
      return updatedshippingList;
    });
    handleChange("company_address", shippingList);
  };

  const handleContactChange = (index, key, value) => {
    setContactList((prevcontactList) => {
      const updatedcontactList = [...prevcontactList];
      updatedcontactList[index][key] = value;

      return updatedcontactList;
    });
    handleChange("contact", contactList);
  };
  const handleBankChange = (index, key, value) => {
    setBankList((prevBankList) => {
      const updatedBankList = [...prevBankList];
      updatedBankList[index][key] = value;
      return updatedBankList;
    });
    handleChange("bank_details", bankList);
  };

  const fetchData = async () => {
    const response = await getCompany();
    setData(response);

    // console.log("response===>", response?.data);
  };

  useEffect(() => {
    console.log("useeffect");
    fetchData();
  }, [reload]);

  const handleChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    // console.log("master", formState);
  };

  const validateForm = () => {
    const newErrors = {};

    clientCompany.forEach((clientcompany, index) => {
      if (!isValidGSTNo(clientcompany.gstin)) {
        newErrors[`clientCompany[${index}].gstin`] = "Invalid gst number";
      }
      if (!validateURL(clientcompany.website_link)) {
        newErrors[`clientCompany[${index}].website_link`] = "Invalid Website URL";
      }
      if (!isValidPanCardNo(clientcompany.pan_number)) {
        newErrors[`clientCompany[${index}].pan_number`] = "Invalid PAN number format";
      }
    });

    contactList.forEach((contact, index) => {
      if (!isValidPhoneNumber(contact.contact_number)) {
        newErrors[`contactList[${index}].contact_number`] = "Invalid contact number";
      }
      if (!isValidEmail(contact.email)) {
        newErrors[`contactList[${index}].email`] = "Invalid email id";
      }
    });
    bankList.forEach((bank, index) => {
      if (!isValid_Bank_Acc_Number(bank.account_no)) {
        newErrors[`bankList[${index}].account_no`] = "Invalid bank account number";
      }
      if (!isValidIFSCCode(bank.ifsc_code)) {
        newErrors[`bankList[${index}].ifsc_code`] = "Invalid IFSC code";
      }
    });

    setErrors(newErrors);
    return newErrors;
  };


  
  const handleSave = async (e) => {
    e.preventDefault();

    try {
      const newErrors = validateForm();

        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          console.log("Validation errors:", newErrors);
          return;
        }
        const formattedFormState = {
          ...formState,
          company_name: clientCompany.map((item) => item.company_name),
          company_alias: clientCompany.map((item) => item.company_alias),
          gstin: clientCompany.map((item) => item.gstin),
          website_link: clientCompany.map((item) => item.website_link),
          pan_number: clientCompany.map((item) => item.pan_number),
          tan_number: clientCompany.map((item) => item.tan_number),
        };
      await addCompany(formattedFormState);
      resetForms();
      toggleForm();
      setReload(!reload);
    } catch (error) {
      console.log(error);
    }
  };
  const resetForms = () => {
    setContactList([
      {
        person_name: "",
        person_alias: "",
        person_designation: "",
        contact_number: "",
        email: "",
      },
    ]);
    setBankList([
      {
        bank_name: "",
        account_no: "",
        account_type: "",
        ifsc_code: "",
        branch: "",
      },
    ]);
    setDeliveryList([
      {
        address_line_one: "",
        address_line_two: "",
        address_line_three: "",
        city_id: "",
        state_id: "",
        state_code: "",
      },
    ]);
    setShippingList([
      {
        address_line_one: "",
        address_line_two: "",
        address_line_three: "",
        city_id: "",
        state_id: "",
        state_code: "",
      },
    ]);
  };

  
  const handleDelete = async (id) => {
    try {
      const response = await deleteCompany(id);
      setReload(!reload);
    } catch (error) {
    }
  };
  
  const handleUpdate = async (e, data) => {
    e.preventDefault();
    console.log("update", data);
    data = { ...data, ...formState };
    const response = await updateCompany(data);
    console.log("rest update", response);
    setReload(!reload);
  };

  const toggleForm = () => {
    setOpen(!open);
    resetForms()
    setFormState({});
  };

  return (
    <div style={{ width: "78%", position: "fixed" }}>
      <CompanyDetailTable
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
        toggleForm={toggleForm}
        column={formConfig}
        formState={formState}
        data={data}
        canView={true}
        title="Company Details"
      />
      <DynamicContact
        title="Add Company Details"
        open={open}
        handleClose={toggleForm}
        formState={formState}
        bankList={bankList}
        contactList={contactList}
        deliveryList={deliveryList}
        shippingList={shippingList}
        handleBankChange={handleBankChange}
        handleContactChange={handleContactChange}
        handleShippChange={handleShippChange}
        // handleShippingAdd={handleShippingAdd}
        handleDelivChange={handleDelivChange}
        handleDeliveryaddAddress={handleDeliveryaddAddress}
        handleAddBank={handleAddBank}
        handleAddInput={handleAddInput}
        handleDeliveryRemoveAddress={handleDeliveryRemoveAddress}
        handleRemoveInput={handleRemoveInput}
        handleRemoveBank={handleRemoveBank}
        handleChange={handleChange}
        states={states}
        cities={cities}
        clientCompany={clientCompany}
        handleComapnyNameChange={handleComapnyNameChange}
        handleCompanyAliasChange={handleCompanyAliasChange}
        handleGstChange={handleGstChange}
        handleLinkChange={handleLinkChange}
        handlePanChange={handlePanChange}
        handleTanChange={handleTanChange}
        handleClick={handleClick}
        handleSave={handleSave}
        formConfig={loadformConfig}
        errors={errors}
        // fullScreen={true}
      />
    </div>
  );
};
