import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LoginIcon from "@mui/icons-material/Login";
import ReceiptLong from "@mui/icons-material/ReceiptLong";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import ArrowForward from "@mui/icons-material/ArrowForward";
import CurrencyRupee from "@mui/icons-material/CurrencyRupeeRounded";
import LayersIcon from "@mui/icons-material/Layers";
import Typography from "@mui/material/Typography";
import { Button, ListItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeRoute } from "../features/navbarSlice";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

export const MainListItems = (props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpne] = useState(false);

  const handleChange = (i) => {
    dispatch(changeRoute(i));
  };

  const mastersList = [
    // { id: 1, name: "Account", link: "account" },
    // { id: 2, name: "Billing", link: "billing" },
    { id: 3, name: "Client Details", link: "client-details" },
    { id: 20, name: "Own Company", link: "company" },
    // { id: 4, name: "Contact", link: "contact" },
    // { id: 5, name: "Contact Person", link: "contact-person" },
    { id: 6, name: "Dimension", link: "dimension" },
    { id: 7, name: "Dimension Unit", link: "dimension-unit" },
    // { id: 8, name: "Employee", link: "employee" },
    { id: 9, name: "Machine", link: "machine" },
    { id: 10, name: "Product", link: "product" },
    { id: 11, name: "Sub Product", link: "sub-product" },
    // { id: 12, name: "Product Category", link: "product-category" },
    { id: 13, name: "Raw Material Master", link: "raw-material-master" },
    { id: 14, name: "Raw Material Category", link: "raw-material-category" },
    // { id: 15, name: "Raw Material Inventory", link: "raw-material-inventory" },
    { id: 16, name: "Stages", link: "stages" },
    { id: 17, name: "Sub Stages", link: "sub-stages" },
    { id: 18, name: "Supplier", link: "supplier" },
    // { id: 19, name: "Transaction", link: "transaction" },
  ];

  const textColor = {
    color: "#637381",
  };

  const toggleArrow = () => {
    return isOpen ? (
      <KeyboardArrowUp sx={{ color: textColor.color  }} />
    ) : (
      <KeyboardArrowDown sx={{ color: textColor.color }} />
    );
  };
  // const handleDropdownItemClick = () => {
  //   setIsOpne(false); // Close the dropdown when an item is clicked
  // };

  return (
    <React.Fragment>
      {/* <ListItem sx={{ paddingX: 2, paddingY: 2 }}>
        <Typography align="center" variant="h6">
          Suchi International
        </Typography>
      </ListItem> */}

      {/* <ListItemButton
        component={NavLink}
        to="/"
        sx={{
          "&.active": {
            paddingLeft: "16px",
            paddingRight: "12px",
            marginBottom: "4px",
             backgroundColor: "#EEF7FF",
            color: "#3ABEF9",
          },
          padding: 2,
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <ListItemIcon>
          <DashboardIcon style={{ color: "#637381", width: 22, height: 22 }} />
        </ListItemIcon>
        <Typography style={{ fontSize: "14px" }}>Raw Material View</Typography>
      </ListItemButton> */}

      <ListItemButton
        component={NavLink}
        to="/dashboard"
        // to="/stageView"
        sx={{
          "&.active": {
            paddingLeft: "16px",
            paddingRight: "12px",
            marginBottom: "4px",
            backgroundColor: "#EEF7FF",
            color: "#3ABEF9",
          },
          padding: 2,
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <ListItemIcon>
          <VisibilityIcon style={{ color: "#637381", width: 22, height: 22 }} />
        </ListItemIcon>
        <Typography style={{ fontSize: "14px" }}>Dashboard</Typography>
      </ListItemButton>

      <ListItemButton
        component={NavLink}
        to="/inventory"
        sx={{
          "&.active": {
            paddingLeft: "16px",
            paddingRight: "12px",
            marginBottom: "4px",
            backgroundColor: "#EEF7FF",
            color: "#3ABEF9",
          },
          padding: 2,
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <ListItemIcon>
          <LayersIcon style={{ color: "#637381", width: 22, height: 22 }} />
        </ListItemIcon>
        <Typography style={{ fontSize: "14px" }}>Inventory</Typography>
      </ListItemButton>

      <ListItemButton
        component={NavLink}
        to="/Purchase Order"
        sx={{
          "&.active": {
            paddingLeft: "16px",
            paddingRight: "12px",
            marginBottom: "4px",
            backgroundColor: "#EEF7FF",
            color: "#3ABEF9",
          },
          padding: 2,
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <ListItemIcon>
          <PeopleIcon style={{ color: "#637381", width: 22, height: 22 }} />
        </ListItemIcon>
        <Typography style={{ fontSize: "14px" }}>Purchase Order</Typography>
      </ListItemButton>

      <ListItemButton
        component={NavLink}
        to="/Purchase"
        sx={{
          "&.active": {
            paddingLeft: "16px",
            paddingRight: "12px",
            marginBottom: "4px",
            backgroundColor: "#EEF7FF",
            color: "#3ABEF9",
          },
          padding: 2,
          "&:hover": {
            backgroundColor: "#f5f5f5", 
          },
        }}
      >
        <ListItemIcon>
          <CurrencyRupee style={{ color: "#637381", width: 22, height: 22 }} />
        </ListItemIcon>
        <Typography style={{ fontSize: "14px" }}>Purchase</Typography>
      </ListItemButton>

      {/* <ListItemButton
        component={NavLink}
        to="/Reports"
        sx={{
          "&.active": {
            paddingLeft: "16px",
            paddingRight: "12px",
            marginBottom: "4px",
            backgroundColor: "#EEF7FF",
            color: "#3ABEF9",
          },
          padding: 2,
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <ListItemIcon>
          <BarChartIcon style={{ color: "#637381", width: 22, height: 22 }} />
        </ListItemIcon>
        <Typography style={{ fontSize: "14px" }}>Reports</Typography>
      </ListItemButton> */}

      {/* <ListItemButton
        component={NavLink}
        to="/Billing"
        sx={{
          "&.active": {
            paddingLeft: "16px",
            paddingRight: "12px",
            marginBottom: "4px",
            backgroundColor: "#EEF7FF",
            color: "#3ABEF9",
          },
          padding: 2,
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <ListItemIcon>
          <ReceiptLong style={{ color: "#637381", width: 22, height: 22 }} />
        </ListItemIcon>
        <Typography style={{ fontSize: "14px" }}>Billing</Typography>
      </ListItemButton> */}

      <li className="master-scroll dropdown">
        <ListItemButton
          // className="master-scroll dropdown"
          onClick={() => setIsOpne(!isOpen)}
          component={NavLink}
          activeClassName="active"
          sx={{
            padding: 2,
            // "&.active": {
            //   backgroundColor: "#EEF7FF",
            //   color: "#3ABEF9",
            // },
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <ListItemIcon>
            <LayersIcon style={{ color: "#637381", width: 22, height: 22 }} />
          </ListItemIcon>
          <Typography style={{ fontSize: "14px"  }}>Masters</Typography>
          <Button
            style={{
              paddingLeft: 70,
            }}
          >
            {toggleArrow()}
          </Button>
        </ListItemButton>
        {isOpen && (
          <ul style={{ listStyleType: "disc", paddingLeft: 40, }}>
            {mastersList.map((master) => (
              <li key={master.id}>
                <ListItemButton
                  component={NavLink}
                  to={"/Masters/" + master.link}
                  activeClassName="active"
                  sx={{
                    "&.active": {
                      backgroundColor: "#EEF7FF",
                      color: "#3ABEF9",
                    },
                    paddingBottom: 3,
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                      // color: "#3ABEF9",
                    },
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      paddingLeft: "16px",
                      addingRight: "12px",
                      marginBottom: "4px",
                      fontWeight: 400,
                      height: "5px",
                    }}
                  >
                    {master.name}
                  </Typography>
                </ListItemButton>
              </li>
            ))}
          </ul>
        )}
      </li>
      <ListItemButton
        component={NavLink}
        to="/Others"
        sx={{
          "&.active": {
            paddingLeft: "16px",
            paddingRight: "12px",
            marginBottom: "4px",
            backgroundColor: "#EEF7FF",
            color: "#3ABEF9",
          },
          padding: 2,
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <ListItemIcon>
          <ArrowForward style={{ color: "#637381", width: 22, height: 22 }} />
        </ListItemIcon>
        <Typography style={{ fontSize: "14px" }}>Others</Typography>
      </ListItemButton>

      <ListItemButton
        component={NavLink}
        to="/login"
        sx={{
          "&.active": {
            paddingLeft: "16px",
            paddingRight: "12px",
            marginBottom: "4px",
            backgroundColor: "#EEF7FF",
            color: "#3ABEF9",
          },
          padding: 2,
          "&:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <ListItemIcon>
          <LoginIcon style={{ color: "#637381", width: 22, height: 22 }} />
        </ListItemIcon>
        <Typography style={{ fontSize: "14px" }}>Login</Typography>
      </ListItemButton>
    </React.Fragment>
  );
};
