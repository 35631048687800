import React, { useState, useEffect } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Grid,
  Typography,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { MdPreview } from "react-icons/md";
import ChangeStage from "./change_stage";
import { getRawWiseData } from "../../services/handlers/get_masters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pl: 4,
  pr: 4,
  pb: 1,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f4f6f8",
    color: "#637381",
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px gray solid",
    borderColor: "#gray",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child th": {
    borderColor: "#909090",
    background: "#fff",
    color: "#637381",
  },
}));

const FixedWidthTableContainer = styled(TableContainer)({
  width: "78%",
  position: "relative",
  maxHeight: "calc(100vh - 200px)",
  overflowY: "auto",
});

const MaterialWiseData = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState({});

  useEffect(() => {
    fetchData();
  }, [reload]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await getRawWiseData();
      setTableData(data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setLoading(false);
    }
  };

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setOpenModal(true);
    console.error("setSelectedRow-->", setSelectedRow);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggle = (index) => {
    setOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const Row = ({ row, index }) => {
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleToggle(index)}
            >
              {open[index] ? <Remove /> : <Add />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.raw_material_name || "-"}
          </TableCell>
          {/* <TableCell>
            <MdPreview
              color="#3ABEF9"
              size={23}
              onClick={() => {
                setSelectedRow(row);
                handleOpenModal();
              }}
            />
          </TableCell> */}
        </TableRow>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
              <Box margin={2}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ padding: 2 }}>
                        Stage Name
                      </StyledTableCell>
                      <StyledTableCell sx={{ padding: 2 }}>
                        Sub Stage Name
                      </StyledTableCell>
                      <StyledTableCell sx={{ padding: 2 }}>
                        Quantity (pcs)
                      </StyledTableCell>
                      <StyledTableCell sx={{ padding: 2 }}>
                        Quantity (weight)
                      </StyledTableCell>
                      <StyledTableCell sx={{ padding: 2 }}>
                        Actions
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.stage_details &&
                      row.stage_details.map(
                        (stage, stageIndex) =>
                          stage.sub_stage_details &&
                          stage.sub_stage_details.map(
                            (subStage, subStageIndex) =>
                              subStage.details &&
                              subStage.details.map((detail, detailIndex) => (
                                <TableRow
                                  key={`${stageIndex}-${subStageIndex}-${detailIndex}`}
                                >
                                  {subStageIndex === 0 && detailIndex === 0 && (
                                    <TableCell
                                      rowSpan={stage.sub_stage_details.length}
                                      sx={{ padding: 2 }}
                                    >
                                      {stage.stage_name || "-"}
                                    </TableCell>
                                  )}
                                  <TableCell sx={{ padding: 2 }}>
                                    {subStage.sub_stage_name || "-"}
                                  </TableCell>
                                  <TableCell sx={{ padding: 2 }}>
                                    {detail.qty || "-"}
                                  </TableCell>
                                  <TableCell sx={{ padding: 2 }}>
                                    {detail.qty_weight || "-"}
                                  </TableCell>
                                  <TableCell>
                                    <MdPreview
                                      color="#3ABEF9"
                                      size={23}
                                      onClick={() => {
                                        // setSelectedRow(row);
                                        handleOpenModal(row);
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))
                          )
                      )}
                    {!row.stage_details && (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No stage details found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <>
      <Grid container justifyContent="flex-end" mb={2} mt={5}>
        <Typography
          variant="h5"
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#212B36",
            fontFamily: ["Public Sans, sans-serif"].join(","),
          }}
        >
          Raw Material View
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          sx={{
            ml: "auto",
            color: "#fff",
            backgroundColor: "#3ABEF9",
            "&:hover": {
              backgroundColor: "#3572EF",
            },
          }}
        >
          Add Raw Material
        </Button>
      </Grid>
      <FixedWidthTableContainer
        component={Paper}
        style={{ width: "78%", position: "fixed" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>Raw Material</StyledTableCell>
              {/* <StyledTableCell>Actions</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {loading && (
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  No rows
                </TableCell>
              </TableRow>
            ) : (
              tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row key={row.id} row={row} index={index} />
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          stickyHeader
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </FixedWidthTableContainer>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={style}>
          <ChangeStage
            onClose={handleCloseModal}
            selectedRow={selectedRow}
            setReload={setReload}
          />
        </Box>
      </Modal>
    </>
  );
};

export default MaterialWiseData;
