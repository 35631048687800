import { toast } from "react-toastify";
import apiHandler from "../apiHandler";

export async function getDimensions() {
  try {
    const response = await apiHandler("GET", "/master/dimension-master");
    const convertedData = response.map((item) => ({
      ...item,
      createdAt: new Date(item.createdAt), // Convert added_date to Date object
      updatedAt: new Date(item.updatedAt), // Convert modified_date to Date object
      value: item?.dimension_name,
    }));
    return convertedData;
  } catch (error) {}
  return [];
}

export async function getDimensionUnits() {
  try {
    const response = await apiHandler("GET", "/master/dimension-unit-master");
    const convertedData = response.map((item) => ({
      ...item,
      createdAt: new Date(item.createdAt), // Convert added_date to Date object
      updatedAt: new Date(item.updatedAt), // Convert modified_date to Date object
      value: item?.dimension_unit_name,
    }));
    return convertedData;
  } catch (error) {}
  return [];
}

export async function getContacts() {
  try {
    const response = await apiHandler("GET", "/master/contact");
    const convertedData = response.map((item) => ({
      ...item,
      createdAt: new Date(item.createdAt), // Convert added_date to Date object
      updatedAt: new Date(item.updatedAt), // Convert modified_date to Date object
    }));
    return convertedData;
  } catch (error) {}
  return [];
}

export async function getProductCategories() {
  try {
    const response = await apiHandler("GET", "/masters/product_category_masters");
    return response?.data;
  } catch (error) {}
  return [];
}

export async function getRawCategories() {
  try {
    const response = await apiHandler("GET", "/master/raw-material/categories");
    const convertedData = response.map((item) => ({
      ...item,
      createdAt: new Date(item.createdAt), // Convert added_date to Date object
      updatedAt: new Date(item.updatedAt), // Convert modified_date to Date object
    }));
    return convertedData;
  } catch (error) {}
  return [];
}

export async function getRawMaterials() {
  try {
    const response = await apiHandler("GET", "/raw-material/get");
    return response?.data;
  } catch (error) {}
  return [];
}
export async function getSubProduct() {
  try {
    const response = await apiHandler("GET", "/sub-product/get");
    return response?.data;
  } catch (error) {}
  return [];
}

export async function getRawStage() {
  try {
    const response = await apiHandler("GET", "/addstage");
    return response.data[0].raw_material;
  } catch (error) {}
  return [];
}

export async function getEmployeeName() {
  try {
    const response = await apiHandler("GET", "/addstage");
    return response.data[1].employees;
  } catch (error) {}
  return [];
}

export async function getMachineName() {
  try {
    const response = await apiHandler("GET", "/addstage");
    return response.data[2].machine;
  } catch (error) {}
  return [];
}

export async function getStages() {
  try {
    const response = await apiHandler("GET", "/masters/stages");
    return response?.data;
  } catch (error) {}
  return [];
}
export async function getsubStages() {
  try {
    const response = await apiHandler("GET", "/masters/sub_stages");
    return response?.data;
  } catch (error) {}
  return [];
}


// export async function getSubStages() {
//   try {
//     const response = await apiHandler("GET", "/masters/sub_stages");
//     const convertedData = response.map((item) => ({
//       ...item,
//       // createdAt: new Date(item.createdAt),
//       // updatedAt: new Date(item.updatedAt),
//       value: item?.sub_stage_name,
//     }));
//     return convertedData;
//   } catch (error) {}
//   return [];
// }


export async function getMachine() {
  try {
    const response = await apiHandler("GET", "/master/machine/");
    const convertedData = response.map((item) => ({
      ...item,
      createdAt: new Date(item.createdAt),
      updatedAt: new Date(item.updatedAt),
    }));
    return convertedData;
  } catch (error) {}
  return [];
}

export async function getSupplier() {
  try {
    const response = await apiHandler("GET", "/supplier/get");
    return response?.data;
  } catch (error) {}
  return [];
}
export async function getCompany() {
  try {
    const response = await apiHandler("GET", "/my-company/get");
    return response?.data;
  } catch (error) {}
  return [];
}
export async function getEmployee() {
  try {
    const response = await apiHandler("GET", "/my-company/get");
    return response?.data;
  } catch (error) {}
  return [];
}

export async function getClientDetails() {
  try {
    const response = await apiHandler("GET", "/client_details/get");
    return response?.data;
  } catch (error) {}
  return [];
}

// export const chnageStage = async () => {
//   try {
//     const response = await apiHandler("GET", "/addstage");
//     return response;
//   } catch (error) {
//     toast.error("Something Went Wrong");
//   }
//   return [];
// };
export const getAllProduct = async () => {
  try {
    const response = await apiHandler("GET", "/masters/product/get");
    return response?.data;
  } catch (error) {
    toast.error("Something Went Wrong");
  }
  return [];
};

export const getAllProductCategory = async () => {
  try {
    const response = await apiHandler(
      "GET",
      "/masters/product_category_masters"
    );

    const mappedData = response?.data[0]?.map((item) => ({
      ...item,
      createdAt: new Date(item.createdAt),
      updatedAt: new Date(item.updatedAt),
      value: item?.category_name,
    }));
    return mappedData;
  } catch (error) {
    toast.error("Something Went Wrong");
  }
  return [];
};

export async function getstageWiseData() {
  try {
    const response = await apiHandler("GET", "/assembly/get");
    return response?.data?.stageWiseData;
  } catch (error) {}
  return [];
}
export async function getRawWiseData() {
  try {
    const response = await apiHandler("GET", "/assembly/get");
    return response?.data?.materialWiseData;
  } catch (error) {}
  return [];
}
