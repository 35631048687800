import apiHandler from "../apiHandler";

export async function getCategory() {
  const response = await apiHandler("GET", "/data/productCategory/get");

  console.log(response);
  const data = [];
  const res = response["Company Details"]["Company name"];
  console.log(res);
  for (var i = 0; i < res.length; i++) {
    const obj = {
      id: res[i]["id"],
      value: res[i]["category_name"],
    };

    data.push(obj);
  }
  console.log(data);
  return data;
}

export async function getState() {
  const response = await apiHandler("GET", "/master/state");
  console.log("state", response);
  return response;
}

export async function getCities() {
  const response = await apiHandler("GET", `/master/city`);
  return response;
}

export async function getCategories() {
  const response = await apiHandler("GET", "/master/raw-material/categories");
  let data = response.map((e) => ({ id: e.id, value: e.category_name }));

  return data;
}

export async function getDimension() {
  const response = await apiHandler("GET", "/master/dimension-master");
  return response;
}

export async function getDimensionUnit() {
  const response = await apiHandler("GET", "/master/dimension-unit-master");
  return response;
}

export async function getProduct() {
  const response = await apiHandler("GET", "/sub-product/get");
  return response?.data;
}
export async function getCategorie() {
  const response = await apiHandler("GET", "/master/raw-material/categories");

  return response;
}
export async function getTaxx() {
  const response = await apiHandler("GET", "/masters/tax_masters");
  return response?.data;
}
export async function getProductStages() {
  const response = await apiHandler("GET", "/masters/stages");
  return response?.data;
}

export async function getProductsubStages() {
  const response = await apiHandler("GET", "/masters/sub_stages");
  return response?.data;
}

export async function getsubProduct() {
  const response = await apiHandler("GET", "/masters/sub_product_masters");
  console.log("responseee",response);
  return response?.data;
}


export async function getRawMaterials() {
  const response = await apiHandler("GET", "/raw-material/get");
  return response?.data;
}
export async function getStages() {
  const response = await apiHandler("GET", "/masters/stages");
  return response;
}

export async function getTax() {
  try {
    const response = await apiHandler("GET", "/masters/tax_masters");
    const data = response?.data.map((e) => ({
      id: e.id,
      value: e.tax_percentage
    }));
    console.log("taxes", data);
    return data;
  } catch (error) {
    console.error("Error fetching tax data:", error);
    return [];
  }
}

export async function getRawMaterial() {
  const response = await apiHandler("GET", "/dropdown/raw-material/get");
  let data = response.map((e) => ({ id: e.id, value: e.raw_material_name }));
  console.log("Raw Material", data);
  return data;
}

export async function getStage() {
  const response = await apiHandler("GET", "/dropdown/stage/get");
  let data = response.map((e) => ({ id: e.id, value: e.stage_name }));
  console.log("Stage", data);
  return data;

}


