import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useCallback } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import { Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import { DynamicForm } from "./form/dynamic_form";
import { CheckForm } from "./form/CheckForm";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";




export default function DynamicDimension({
  formState,
  formConfig,
  handleCheckBox,
  name,
  open,
  fullScreen,
  handleClose,
  handleSave,
  title,
  handleChange,
  dimensionList,
  handleDimensionChange,
  handleAddDimension,
  dimensionsUnit,
  dimensions,
  RawList,
  TaxList,
  tax,
  raw,
  handleRaw,
  handleTax,
  handledimensionListRemove,
  handleRawNameChange,
  handleARawliasChange,
  RawNameAlias,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ "& .MuiDialog-paper": { width: "50%", maxWidth: "none" } }}
    >
      <form onSubmit={handleSave}>
        <AppBar sx={{ position: "relative", backgroundColor: "#3ABEF9" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#fff" }}
              variant="h7"
              component="div"
            >
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DynamicForm
            formState={formState}
            handleChange={handleChange}
            handleSave={handleSave}
            formConfig={formConfig}
            handleCheckBox={handleCheckBox}
          />
        </DialogContent>
        <DialogContent sx={{ pb: 1, pt: 1 }}>
          <Box sx={{ ml: 2, display: "flex", gap: 1 }}>
            {RawNameAlias?.map((item, index) => (
              <Box key={index} sx={{ display: "flex", gap: 2, width: "100%" }}>
                <Box sx={{ width: "40%" }}>
                  <TextField
                    label="Raw Material Name"
                    name="raw_material_name"
                    value={item.raw_material_name}
                    onChange={(e) =>
                      handleRawNameChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                </Box>
                <Box sx={{ width: "40%" }}>
                  <TextField
                    label="Raw Material Alias"
                    name="raw_material_alias"
                    value={item.raw_material_alias}
                    onChange={(e) =>
                      handleARawliasChange(index, e.target.name, e.target.value)
                    }
                    fullWidth
                    required
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogContent>
          <Box sx={{ ml: 2, display: "flex", gap: 2 }}>
            {RawList?.map((item, index) => (
              <Box key={index} sx={{ gap: 1, width: "40%" }}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={raw}
                    getOptionLabel={(option) => option.category_name}
                    value={
                      raw.find(
                        (s) => s.id === item?.raw_material_category_id
                      ) || null
                    }
                    onChange={(event, newValue) =>
                      handleRaw(
                        index,
                        "raw_material_category_id",
                        newValue?.id || ""
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Raw Material Category"
                        InputLabelProps={{ required: true }}
                      />
                    )}
                    fullWidth
                    required
                  >
                    {raw?.map((e) => (
                      <MenuItem key={e?.id} value={e?.id}>
                        {e?.category_name}
                      </MenuItem>
                    ))}
                  </Autocomplete>
                </FormControl>
              </Box>
            ))}
            {TaxList?.map((item, index) => (
              <Box key={index} sx={{ gap: 2, width: "40%" }}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={tax}
                    getOptionLabel={(option) => option.tax_percentage}
                    value={
                      tax.find((s) => s.id === item?.tax_percentage_id) || null
                    }
                    onChange={(event, newValue) =>
                      handleTax(index, "tax_percentage_id", newValue?.id || "")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tax Percentage"
                        InputLabelProps={{ required: true }}
                      />
                    )}
                    fullWidth
                    margin="normal"
                    required
                  >
                    {tax?.map((e) => (
                      <MenuItem key={e?.id} value={e?.id}>
                        {e?.tax_percentage}
                      </MenuItem>
                    ))}
                  </Autocomplete>
                </FormControl>
              </Box>
            ))}
          </Box>
        </DialogContent>

        <DialogContent>
          <Box sx={{ ml: 2 }}>
            <span className="block mb-2 text-base font-medium text-gray-900">
              {name} Dimension :
              <IconButton
                onClick={handleAddDimension}
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
              >
                <AddCircleOutline />
              </IconButton>
            </span>
            <div>
              {dimensionList?.map((item, index) => (
                <Box key={index} sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <FormControl sx={{width:"40%"}}>
                    <Autocomplete
                      options={dimensions}
                      getOptionLabel={(option) => option.dimension_name}
                      value={
                        dimensions.find((s) => s.id === item?.dimension_id) ||
                        null
                      }
                      onChange={(event, newValue) =>
                        handleDimensionChange(
                          index,
                          "dimension_id",
                          newValue?.id || ""
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Dimension"
                          InputLabelProps={{ required: true }}
                        />
                      )}
                      fullWidth
                      required
                    >
                      {dimensions?.map((e) => (
                        <MenuItem key={e?.id} value={e?.id}>
                          {e?.dimension_name}
                        </MenuItem>
                      ))}
                    </Autocomplete>
                  </FormControl>
                  <FormControl sx={{width:"40%"}}>
                    <Autocomplete
                      options={dimensionsUnit}
                      getOptionLabel={(option) => option.dimension_unit_name}
                      value={
                        dimensionsUnit.find(
                          (s) => s.id === item?.dimension_unit_id
                        ) || null
                      }
                      onChange={(event, newValue) =>
                        handleDimensionChange(
                          index,
                          "dimension_unit_id",
                          newValue?.id || ""
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Dimension Unit"
                          InputLabelProps={{ required: true }}
                        />
                      )}
                      fullWidth
                      required
                    >
                      {dimensionsUnit?.map((e) => (
                        <MenuItem key={e?.id} value={e?.id}>
                          {e?.dimension_unit_name}
                        </MenuItem>
                      ))}
                    </Autocomplete>
                  </FormControl>
                  {index !== dimensionList.length - 1 && (
                    <IconButton
                      onClick={() => handledimensionListRemove(index)}
                      sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              ))}
            </div>
          </Box>
        </DialogContent>

        {/* {formConfig.map((field) => {
          if (field.type === "checkbox") {
            return (
              <DialogContent key={field.field}>
                <CheckForm
                  value={formState[field.field]}
                  name={field.headerName}
                  handleCheckBox={handleCheckBox}
                />
              </DialogContent>
            );
          }
          return null;
        })} */}

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            sx={{
              color: "#fff",
              minWidth: 100,
              backgroundColor: "#3ABEF9",
              "&:hover": {
                backgroundColor: "#3572EF",
              },
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{
              minWidth: 100,
              borderColor: "#3ABEF9",
              "&:hover": {
                borderColor: "#3572EF",
              },
              color: "#3ABEF9",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
