import React, { useEffect, useState } from "react";
import { getStages } from "../../services/handlers/get_masters";
import { deleteStage } from "../../services/handlers/delete_masters";
import { updateStage } from "../../services/handlers/update_masters";
import DynamicStage from "../../components/Dynamic_stages";
import DynamicStages from "../../components/Dynamic_Stage";
import { addStage } from "../../services/handlers/add_masters";

const MasterStage = () => {
  const formConfig = [
   
    {
      field: "stage_name",
      headerName: "Stage Name",
      minWidth: 200,
      flex: 0.5,
      // editable: true,
      active: true,
      required:true
    },
    {
      field: "stage_alias",
      headerName: "Stage Alias",
      minWidth: 200,
      flex: 0.4,
      // editable: true,
      active: true,
      required:true
    },
   
    // {
    //   field: "added_by",
    //   headerName: "Added By",
    //   width: 180,
    // },

    // {
    //   field: "modified_by",
    //   headerName: "Modified By",
    //   width: 180,
    // },
    // {
    //   field: "createdAt",
    //   headerName: "Added Date",
    //   type: "date",
    //   width: 180,
    // },
    // {
    //   field: "updatedAt",
    //   headerName: "Modified Date",
    //   type: "date",
    //   width: 180,
    // },
  ];

  const [data, setData] = useState([]);
  const [loadformConfig, setFormConfig] = useState(formConfig);
  const [reload, setReload] = useState(false);
  const [formState, setFormState] = useState({});
  const [open, setOpen] = useState(false);
  

  const handleChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    fetchData();
  }, [reload]);

  async function fetchData() {
    const response = await getStages();
    setData(response);
  }


  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await addStage(formState);
      toggleForm();
      setReload(!reload);
    } catch (error) {
      console.log(error);
    }
  };


  const handleDelete = async (id) => {
    try {
      const response = await deleteStage(id);
      // setReload(!reload);
    } catch (error) {}
  };
  
  const handleUpdate = async (e, data) => {
    e.preventDefault();
    console.log("update", data);
    const response = await updateStage(data);
    console.log("rest update", response);
    setReload(!reload);
  };

  const toggleForm = () => {
    setOpen(!open);
    setFormState({});
  };

  return (
    <div style={{ width: "78%", position: "fixed" }}>
      <DynamicStages
        title="Stages"
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
        toggleForm={toggleForm}
        column={formConfig}
        formState={formState}
        data={data}
        canView={true}
      />
      <DynamicStage
        title="Add Stage"
        open={open}
        handleClose={toggleForm}
        formState={formState}
        handleChange={handleChange}
        handleSave={handleSave}
        formConfig={loadformConfig}
        // fullScreen={true}
      />
    </div>
  );
};

export default MasterStage;
