import React from "react";
import Imageslider from "./Imageslider";
import { AppBar, IconButton, Toolbar, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import "./MasterDisplay.css"

function Capitalize(str) {
  str = str.toString();
  str = str.replace(/_/g, " ");
  return str.charAt(0).toUpperCase() + str.slice(1);
}
const MasterDisplay = ({ handleClose, data, header }) => {
  const [imageType, setImageType] = useState("");
  console.log("view header", data);
  console.log("imageTypeimageType", imageType);


  useEffect(() => {
    if (header) {
      switch (header) {
        case "product":
        case "client":
        case "company":
        case "supplier":
        case "sub-product":
          setImageType(header);
          break;
        default:
          console.warn(`Unexpected data.type: ${header}`);
      }
    }
  }, [header]);

  return (
    <div className="flex flex-col h-[100%]" style={{}}>
      <div style={{ width: "30%", margin: "auto" }}>
        <AppBar sx={{ position: "sticky" }} />
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={handleClose}
            aria-label="close"
            style={{ color: "red", backgroundColor: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <div className="mt-5 image-display-box">
          <Imageslider data={data} imageType={imageType} />
        </div>
      </div>
    </div>
  );
};

export default MasterDisplay;
