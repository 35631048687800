import React, { useState, useEffect } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Grid,
  Typography,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import { MdPreview } from "react-icons/md";
import { Link } from "react-router-dom";
import ChangeStageView from "./Change_Stage_View";
import { getstageWiseData } from "../../services/handlers/get_masters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  pl: 4,
  pr: 4,
  pb: 1,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f4f6f8",
    color: "#637381",
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px gray solid",
    borderColor: "#gray",
    padding: "8px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child th": {
    borderColor: "#909090",
    background: "#fff",
    color: "#637381",
  },
}));

const FixedWidthTableContainer = styled(TableContainer)({
  width: "78%",
  position: "relative",
  // maxHeight: 600,
  maxHeight: "calc(100vh - 200px)",
  overflowY: "auto",
  marginBottom: 50, 
});

const PaginationContainer = styled("div")({
  position: "fixed",
  bottom: 0,
  // left: "50%",
  // transform: "translateX(-50%)",
  width: "78%",
  backgroundColor: "#fff",  
  zIndex: 1100,
  marginTop: "-1px",
});

const StageView = ({ row }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // console.log("tableee", tableData);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await getstageWiseData();
      setTableData(data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setLoading(false);
    }
  };

  const handleToggle = (index) => {
    setOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleOpenModal = (row, detail,title) => {
    setSelectedRow({ ...row, detail });
    setModalTitle(title);
    setOpenModal(true);
  };
  console.log("setSelectedRow", setSelectedRow);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Row = ({ row, index }) => {
    let prevRawMaterial = null;
    let prevStageName = null;

    return (
      <>
        {row?.material_details.map((detail, idx) => {
          const isFirstRow = idx === 0;
          const isSameRawMaterial =
            detail.raw_material_name === prevRawMaterial;
          const isSameStageName = row.stage_name === prevStageName;

          const shouldRenderRawMaterial = isFirstRow || !isSameRawMaterial;
          const shouldRenderStageName = isFirstRow || !isSameStageName;

          prevRawMaterial = detail.raw_material_name;
          prevStageName = row.stage_name;

          return (
            <TableRow key={idx} sx={{ "& > *": { borderBottom: "unset" } }}>
              {/* <TableCell></TableCell> */}
              {shouldRenderRawMaterial && (
                <TableCell rowSpan={row.material_details.length}>
                  {detail.raw_material_name || "-"}
                </TableCell>
              )}
              {shouldRenderStageName && (
                <TableCell rowSpan={row.material_details.length}>
                  {row.stage_name || "-"}
                </TableCell>
              )}
              <TableCell>{detail?.sub_stage_name || "-"}</TableCell>
              <TableCell>{detail?.qty_pcs || "-"}</TableCell>
              <TableCell>{detail?.balance || "-"}</TableCell>
              <TableCell>
                <MdPreview
                  color="#3ABEF9"
                  size={23}
                  onClick={() => {
                    handleOpenModal(row, detail,"Change Stage");
                  }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };
  return (
    <>
      {/* <Grid container justifyContent="flex-start" >
        <Typography variant="h5" component="h1">
          Stage View
        </Typography>
      </Grid> */}
      <Grid container justifyContent="flex-end" mb={2} mt={5}>
        <Typography
          variant="h5"
          component="h1"
          style={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#212B36",
            fontFamily: ["Public Sans, sans-serif"].join(","),
          }}
        >
          Dashboard
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenModal({}, {}, "Add Raw Material")}
          sx={{
            ml: "auto",
            color: "#fff",
            backgroundColor: "#3ABEF9",
            "&:hover": {
              backgroundColor: "#3572EF",
            },
          }}
        >
          Add Raw Material
        </Button>
      </Grid>
      <FixedWidthTableContainer
        component={Paper}
        style={{ width: "78%", position: "fixed" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {/* <StyledTableCell /> */}
              <StyledTableCell>Raw Material</StyledTableCell>
              <StyledTableCell>Stage Name</StyledTableCell>
              <StyledTableCell>Sub Stage</StyledTableCell>
              <StyledTableCell>Input Qty</StyledTableCell>
              <StyledTableCell>Balance</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          {/* <TableBody>
            {tableData?.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody> */}

          <TableBody>
            {tableData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  {loading && ( // Conditional rendering of loader
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  No rows
                </TableCell>
              </TableRow>
            ) : (
              tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row key={row.id} row={row} index={index} />
                ))
            )}
          </TableBody>
        </Table>
        <PaginationContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </PaginationContainer>
      </FixedWidthTableContainer>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={style}>
          <ChangeStageView
            setTableData={setTableData}
            onClose={handleCloseModal}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            openModal={openModal}
            headerTitle={modalTitle}
          />
        </Box>
      </Modal>
    </>
  );
};

export default StageView;
