import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useCallback } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import { Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import { DynamicForm } from "./form/dynamic_form";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";

export default function DynamicEmploye({
  formState,
  formConfig,
  handleCheckBox,
  name,
  open,
  fullScreen,
  handleClose,
  handleSave,
  title,
  handleChange,
  employeList,
  handleEmployeeChange,
  states,
  cities,
}) {
  return (
    <Dialog  open={open} onClose={handleClose}  sx={{ '& .MuiDialog-paper': { width: '40%', maxWidth: 'none' } }}>
      <form onSubmit={handleSave}>
        <AppBar sx={{ position: "relative",backgroundColor:'#3ABEF9' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h7" component="div">
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DynamicForm
            formState={formState}
            handleChange={handleChange}
            handleSave={handleSave}
            formConfig={formConfig}
            handleCheckBox={handleCheckBox}
          />
        </DialogContent>
        <DialogContent>
          <Box sx={{ ml: 2,  gridColumn: "span 2" }}>
            <span className="block mb-2 text-base font-medium text-gray-900">
              {name} Address :
            </span>
            <div>
              {employeList?.map((item, index) => (
                <Box sx={{ display: "inline-flex", gap: 2 }}>
                  <Box>
                    <TextField
                      label="Address Line 1..."
                      name="address_line_one"
                      value={item.address_line_one}
                      onChange={(e) =>
                        handleEmployeeChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      required
                    />
                    <TextField
                      label="Address Line 2..."
                      name="address_line_two"
                      value={item.address_line_two}
                      onChange={(e) =>
                        handleEmployeeChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      required
                    />
                    <TextField
                      label="Address Line 3..."
                      name="address_line_three"
                      value={item.address_line_three}
                      onChange={(e) =>
                        handleEmployeeChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>

                  <Box>
                    <FormControl fullWidth>
                      <Autocomplete
                        options={states}
                        getOptionLabel={(option) => option.state_name}
                        value={
                          states.find((s) => s.id === item?.state_id) || null
                        }
                        onChange={(event, newValue) =>
                          handleEmployeeChange(
                            index,
                            "state_id",
                            newValue?.id || ""
                          )
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="State" />
                        )}
                        required
                        fullWidth
                      >
                        {states &&
                          states.map((e) => (
                            <MenuItem key={e.id} value={e.id}>
                              {e.state_name}
                            </MenuItem>
                          ))}
                      </Autocomplete>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                      <Autocomplete
                        options={cities}
                        getOptionLabel={(option) => option.city_name}
                        value={
                          cities.find((s) => s.id === item?.city_id) || null
                        }
                        onChange={(event, newValue) =>
                          handleEmployeeChange(
                            index,
                            "city_id",
                            newValue?.id || ""
                          )
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="City" />
                        )}
                        required
                        fullWidth
                      >
                        {cities?.map((e) => (
                          <MenuItem key={e?.id} value={e?.id}>
                            {e?.city_name}
                          </MenuItem>
                        ))}
                      </Autocomplete>
                    </FormControl>

                    <TextField
                      label="State Code"
                      name="state_code"
                      type="number"
                      value={item.state_code}
                      onChange={(e) =>
                        handleEmployeeChange(index, e.target.name, e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      required
                    />
                  </Box>
                  {/* {index === employeList.length - 1 && (
                    <Box display="flex" alignItems="center">
                      <IconButton onClick={() => handleShippingAdd()}>
                        <AddCircleOutline />
                      </IconButton>
                      <Typography>Shipping Address</Typography>
                    </Box>
                  )} */}
                </Box>
              ))}
            </div>
          </Box>
        </DialogContent>

    
        <DialogActions sx={{ px: 3 }}>
          <Button
            sx={{
              minWidth: 100,
              backgroundColor: "#3ABEF9",
              "&:hover": {
                backgroundColor: "#3572EF",
              },
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{ minWidth: 100,borderColor:"#3ABEF9", "&:hover": {
              borderColor: "#3572EF",
            }, color:"#3ABEF9" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
