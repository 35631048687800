import React, { useEffect, useState } from "react";
import { getCategory } from "../../services/handlers/get_dropdowns";
import DynamicFormDialog from "../../components/dynamic_form_dialogue";
import MasterTable from "../../components/master_table";
import { getEmployee } from "../../services/handlers/get_masters";
import { addEmployee } from "../../services/handlers/add_masters";
import DynamicEmploye from "../../components/Dynamic_Employe";
import { getState, getCities } from "../../services/handlers/get_dropdowns";
import { toast } from "react-toastify";
import validator from "validator";

function isValidEmail(email) {
  const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return pattern.test(email);
}

function isValidPhoneNumber(phoneNumber) {
  const pattern = /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;
  return pattern.test(phoneNumber);
}

function isValid_Aadhaar_Number(aadhaar_number) {
  let regex = new RegExp(/^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/);
  return aadhaar_number != null && regex.test(aadhaar_number);
}

function isValidPanCardNo(panCardNo) {
  let regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  return regex.test(panCardNo);
}

function isValid_Bank_Acc_Number(bank_account_number) {
  let regex = /^[0-9]{9,18}$/;
  return bank_account_number != null && regex.test(bank_account_number);
}

export const MasterEmployee = () => {
  const formConfig = [
    {
      field: "employee_name",
      headerName: "Employee Name",
      width: 200,
      editable: true,
      active: true,
      required: true,
    },
    {
      field: "employee_alias",
      headerName: "Employee Alias",
      width: 200,
      editable: true,
      active: true,
      required: true,
    },
    {
      field: "employee_code",
      headerName: "Employee Code",
      width: 200,
      editable: true,
      active: true,
      required: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
      active: true,
      required: true,
      validator: isValidEmail,
    },
    {
      field: "phone",
      headerName: "phone Number",
      width: 200,
      editable: true,
      active: true,
      required: true,
      validator: isValidPhoneNumber,

    },
    {
      field: "aadhar_number",
      headerName: "Aadhar Number",
      width: 200,
      editable: true,
      active: true,
      required: true,
      validator: isValid_Aadhaar_Number,
    },
    {
      field: "pan_number",
      headerName: "Pan Number",
      width: 200,
      hide: true,
      active: true,
      required: true,
      validator: isValidPanCardNo,

    },
    {
      field: "bank_account_id",
      headerName: "Bank Account",
      width: 200,
      hide: true,
      active: true,
      required: true,
      validator: isValid_Bank_Acc_Number,

    },

    {
      field: "contact_email_id",
      headerName: "Contact Email",
      width: 200,
      hide: true,
      active: true,
      required: true,
      validator: isValidEmail,

    },

    {
      field: "file_id",
      headerName: "File",
      multiple: false,
      type: "image",
      width: 0,
      hide: true,
      editable: true,
    },
    // {
    //   field: "isActive",
    //   headerName: "",
    //   type: "number",
    //   align: "start",
    //   headerAlign: "center",
    //   width: 0,
    // },
    // {
    //   field: "address_id",
    //   headerName: "Employee Address",
    //   type: "address",
    //   width: 200,
    //   hide: true,
    //   editable: true,
    //   active: true,
    // },

    // {
    //   field: "added_by",
    //   headerName: "Added By",
    //   width: 180,
    //   hide: true,
    // },

    // {
    //   field: "modified_by",
    //   headerName: "Modified By",
    //   width: 180,
    //   hide: true,
    // },
    // {
    //   field: "added_date",
    //   headerName: "Added Date",
    //   type: "date",
    //   width: 180,
    // },
    // {
    //   field: "modified_date",
    //   headerName: "Modified Date",
    //   type: "date",
    //   width: 180,
    //   hide: true,
    // },
  ];


  const [loadformConfig, setFormConfig] = useState(formConfig);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [formState, setFormState] = useState({});
  const [open, setOpen] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([])

  const handleChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    // console.log(formState);
  };
  // useEffect(() => {
  //   console.log("useeffect");
  //   fetchData();
  // }, [reload]);

  // async function fetchData() {
  //   const response = await getEmployee();
  //   setData([...response]);
  // }

  const [employeList, setEmployeList] = useState([
    {
      address_line_one: "",
      address_line_two: "",
      address_line_three: "",
      city_id: "",
      state_id: "",
      state_code: "",
    },
  ]);
  

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const data = await getState();
        setStates(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      // debugger;
      try {
        const data = await getCities();
        // console.log("data", data);
        setCities(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCities();
  }, []);

  const handleEmployeeChange = (index, key, value) => {
    setEmployeList((prevemployeList) => {
      const updatedemployeList = [...prevemployeList];
      updatedemployeList[index][key] = value;
      return updatedemployeList;
    });
    handleChange("address_id", employeList);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const email = formState.email;     
    if (!isValidEmail(email)) {
      toast.warning("Invalid email id");
      return;
    }
   
    const Phoneno = formState.phone;     
    if (!isValidPhoneNumber(Phoneno)) {
      toast.warning("Invalid phone number");
      return;
    }

    const aadharNo = formState.aadhar_number;     
    if (!isValid_Aadhaar_Number(aadharNo)) {
      toast.warning("Invalid aadhar number");
      return;
    }

    const panNumber = formState.pan_number;     
    if (!isValidPanCardNo(panNumber)) {
      toast.warning("Invalid pan number");
      return;
    }

    const bankAccount = formState.bank_account_id;     
    if (!isValid_Bank_Acc_Number(bankAccount)) {
      toast.warning("Invalid bank account number");
      return;
    }

    const Email = formState.contact_email_id;     
    if (!isValidEmail(Email)) {
      toast.warning("Invalid email id");
      return;
    }
   

    try {
      await addEmployee(formState);
      resetForms();
      toggleForm();
      setReload(!reload);
    } catch (error) {
      console.log(error);
    }
  };
  const resetForms = () => {
    setEmployeList([
      {
        address_line_one: "",
        address_line_two: "",
        address_line_three: "",
        city_id: "",
        state_id: "",
        state_code: "",
      },
    ]);
  };

  const toggleForm = () => {
    setOpen(!open);
    setFormState({});
  };

  return (
    <>
      <div style={{ width: "75%", position: "fixed" }}>
        <MasterTable
          title="Employees"
          toggleForm={toggleForm}
          column={formConfig}
          formState={formState}
          data={data}
          canView={true}
        />
        <DynamicEmploye
          title="Add Employee"
          open={open}
          handleClose={toggleForm}
          formState={formState}
          handleChange={handleChange}
          handleSave={handleSave}
          formConfig={loadformConfig}
          handleEmployeeChange={handleEmployeeChange}
          employeList={employeList}
          states={states}
          cities={cities}
          // fullScreen={true}
        />
      </div>
    </>
  );
};
