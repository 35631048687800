import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

export default function FixedSizeGrid({columns,rows}) {


  return (
    <div style={{ width: '100%' }}>
      <div style={{ height: 350, width: '100%' }}>
        <DataGrid rows={[]} columns={columns}  sx={{
                "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f4f6f8",
            color: "#637381",
            fontSize: 14,
            fontFamily: ["Public Sans, sans-serif"].join(","),
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-withBorderColor": {
            borderRight: 0,
            borderLeft: 0,
          },
          "& .MuiDataGrid-cell": {
            borderColor: "gray",
            borderRight: 0,
            borderLeft: 0,
            color: "#212B36",
            fontSize:14,
            fontWeight: 400,
            fontFamily: ["Public Sans, sans-serif"].join(","),
            borderBottom: "solid 1px rgba(145, 158, 171, 0.24)",
          },
         
              }}/>
      </div>
    </div>
  );
}