import apiHandler from "../apiHandler";
import axios from 'axios';
import { toast } from "react-toastify";

export async function deleteDimensions(id) {
  try {
    const response = await apiHandler(
      "DELETE",
      `/master/dimension-master/${id}`
    );
    toast.success("Dimesion deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function deleteDimensionUnits(id) {
  try {
    const response = await apiHandler(
      "DELETE",
      `/master/dimension-unit-master/${id}`
    );
    toast.success("Dimesion Unit deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function deleteContact(id) {
  try {
    const response = await apiHandler("DELETE", `/master/contact/${id}`);
    toast.success("Contact deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function deleteProductCategory(id) {
  try {
    const response = await apiHandler(
      "DELETE",
      `/master/product/categories/${id}`
    );
    toast.success("Category deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}
export async function deleteSubProduct(id) {
  try {
    const response = await apiHandler(
      "POST",
      `/delete/sub_product_masters/${id}`
    );
    toast.success("Sub Product deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}
export async function deleteProduct(id) {
  try {
    const response = await apiHandler(
      "POST",
      `/delete/product_masters/${id}`
    );
    toast.success("Product deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function deleteRawCategory(id) {
  try {
    const response = await apiHandler(
      "DELETE",
      `/master/raw-material/categories/${id}`
    );
    toast.success("Category deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function deleteRawMaterial(id) {
  try {
    const response = await apiHandler(
      "POST",
      `/delete/raw_material_masters/${id}`
    );
    toast.success("Deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function deleteStage(id) {
  try {
    const response = await apiHandler("POST", `/delete/stages/${id}`);
    toast.success("Stage deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function deleteSubStage(id) {
  try {
    const response = await apiHandler("POST", `/delete/sub_stages/${id}`);
    toast.success("Sub Stage deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function deleteMachine(id) {
  try {
    const response = await apiHandler("DELETE", `/master/machine/${id}`);
    toast.success("Machine deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

export async function deleteSupplier(id) {
  try {
    const response = await apiHandler("POST", `/delete/suppliers/${id}`);
    toast.success("Supplier deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}
export async function deleteCompany(id) {
  try {
    const response = await apiHandler(
      "POST",
      `/delete/my_company_master/${id}`
    );
    toast.success("Company deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}
export async function deleteClientDetail(id) {
  try {
    const response = await apiHandler(
      "POST",
      `/delete/client_details/${id}`
    );
    toast.success("Client Detail deleted Successfully");
    return response;
  } catch (error) {
    toast.error("Something went wrong");
  }
}

