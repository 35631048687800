import React, { useEffect, useState } from "react";
import { getTax, getProduct,getCategories,getCategorie,getTaxx } from "../../services/handlers/get_dropdowns";
import { addProduct } from "../../services/handlers/add_masters";
import DynamicSubProductDetails from "../../components/Dynamic_sub_product_details";
import { getAllProduct } from "../../services/handlers/get_masters";
import { deleteProduct } from "../../services/handlers/delete_masters";
import { getsubProduct } from "../../services/handlers/get_dropdowns";
import { toast } from "react-toastify";
import DynamicProduct from "../../components/Dynamic_Product";

export const MasterProduct = () => {
  const [formState, setFormState] = useState({});
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);


  const formConfig = [
    {
      field: "file_name",
      headerName: "Files",
      multiple: false,
      type: "image",
      imageType: "product",
      width: 200,
      hide: true,
      editable: true,
    },
  ];

  const [loadformConfig, setFormConfig] = useState(formConfig);
  const [subproduct, setSubProduct] = useState([]);
  const [product, setProduct] = useState([]);
  const [tax, setTax] = useState([]);
  const [productErrors, setProductErrors] = useState([]);
  const [taxErrors, setTaxErrors] = useState([]);
  const [subProductErrors, setSubProductErrors] = useState([]);

  const validateFields = () => {
    const newProductErrors = ProductList.map((item) =>
      item?.product_category_id ? false : true
    );
    const newTaxErrors = TaxList.map((item) =>
      item?.tax_percentage_id ? false : true
    );
    const newSubProductErrors = subProductList.map((item) =>
      item?.sub_product_id ? false : true
    );

    setProductErrors(newProductErrors);
    setTaxErrors(newTaxErrors);
    setSubProductErrors(newSubProductErrors);
    return (
      newProductErrors.every((error) => !error) &&
      newTaxErrors.every((error) => !error) &&
      newSubProductErrors.every((error) => !error)
    );
  };
  
  const [subProductList, setSubProductList] = useState([
    {
      sub_product_id: "",
    },
  ]);
  const [ProductList, setProductList] = useState([
    {
      product_category_id: "",
    },
  ])
  const [TaxList, setTaxList] = useState([
    {
      tax_percentage_id: "",
    },
  ])

  const [ProductNameAlias, setProductNameAlias] = useState([
    {
      product_name: "",
      product_alias: "",
    },
  ]);
  

  const handleNameChange = (index, key, value) => {
    setProductNameAlias((prevProductNameAlias) => {
      const updatedProductNameAlias = [...prevProductNameAlias];
      updatedProductNameAlias[index][key] = value;
      return updatedProductNameAlias;
    });
    handleChange("product_name", ProductNameAlias);
  };

  const handleAliasChange = (index, key, value) => {
    setProductNameAlias((prevProductNameAlias) => {
      const updatedProductNameAlias = [...prevProductNameAlias];
      updatedProductNameAlias[index][key] = value;
      return updatedProductNameAlias;
    });
    handleChange("product_alias", ProductNameAlias);
  };


  const handleAddSubProduct = () => {
    setSubProductList([
      ...subProductList,
      {
        sub_product_id: "",
      },
    ]);
  };

  const handleSubPeoductRemove = (index) => {
    const newList = subProductList.filter((_, i) => i !== index);
    setSubProductList(newList);
  };

  const handleProduct = (index, key, value) => {
    setProductList((prevProductList) => {
      const updatedProductList = [...prevProductList];
      updatedProductList[index][key] = value;

      return updatedProductList;
    });
    handleChange("product_category_id", ProductList);
  };

  const handleTax = (index, key, value) => {
    setTaxList((prevTaxList) => {
      const updatedTaxList = [...prevTaxList];
      updatedTaxList[index][key] = value;

      return updatedTaxList;
    });
    handleChange("tax_percentage_id", TaxList);
  };


  const handleSubProduct = (index, key, value) => {
    setSubProductList((prevsubProductList) => {
      const updatedsubProductList = [...prevsubProductList];
      updatedsubProductList[index][key] = value;

      return updatedsubProductList;
    });
    handleChange("sub_product_id", subProductList);
  };

  useEffect(() => {
    const fetchSubProduct = async () => {
      try {
        const data = await getsubProduct();
        setSubProduct(data);
      } catch (error) {
      }
    };

    fetchSubProduct();
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const data = await getCategorie();
        setProduct(data);
      } catch (error) {
      }
    };

    fetchProduct();
  }, []);
  
  useEffect(() => {
    const fetchTax = async () => {
      try {
        const data = await getTaxx();
        setTax(data);
      } catch (error) {
      }
    };

    fetchTax();
  }, []);

  const handleChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    console.log(formState);
  };

  useEffect(() => {
    fetch();
  }, [reload]);

  async function fetch() {
    const response = await getAllProduct();
    setData(response);
    console.log("ress", response);
  }

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }
    try {
      const formattedFormState = {
        ...formState,
        product_category_id: formState.product_category_id.map((item) => item.product_category_id),
        tax_percentage_id: formState.tax_percentage_id.map((item) => item.tax_percentage_id),
        sub_product_id: formState.sub_product_id.map((item) => item.sub_product_id),
        product_name: formState.product_name.map((item) => item.product_name),
        product_alias: formState.product_alias.map((item) => item.product_alias),
      };
      await addProduct(formattedFormState);
      resetForms()
      toggleForm();
      setReload(!reload);
    } catch (error) {
      console.error(error);
    }
  };

  const resetForms = () => {
    setSubProductList([
      {
        sub_product_id: "",
      },
    ]);
    setProductList([
      {
        product_category_id: "",
      },
    ]);
    setTaxList([
      {
        tax_percentage_id: "",
      },
    ]);
    setProductNameAlias([
      {
        product_name: "",
        product_alias: "",
      },
    ]);

  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteProduct(id);
      setReload(!reload);
    } catch (error) {}
  };

  const toggleForm = () => {
    resetForms()
    setOpen(!open);
    setFormState({});
  };

  return (
    <div style={{ width: "78%", position: "fixed" }}>
      <DynamicProduct
        title="Product"
        handleDelete={handleDelete}
        // handleUpdate={handleUpdate}
        toggleForm={toggleForm}
        column={formConfig}
        // handleCheckBox={handleCheckBox}
        formState={formState}
        canView={true}
        data={data}
      />
      <DynamicSubProductDetails
        title="Add Product"
        open={open}
        handleClose={toggleForm}
        formState={formState}
        handleChange={handleChange}
        subProductList={subProductList}
        ProductList={ProductList}
        TaxList={TaxList}
        handleSubProduct={handleSubProduct}
        handleProduct={handleProduct}
        handleTax={handleTax}
        handleAddSubProduct={handleAddSubProduct}
        handleSubPeoductRemove={handleSubPeoductRemove}
        handleNameChange={handleNameChange}
        handleAliasChange={handleAliasChange}
        ProductNameAlias={ProductNameAlias}
        subproduct={subproduct}
        product={product}
        tax={tax}
        subProductErrors={subProductErrors}
        taxErrors={taxErrors}
        productErrors={productErrors}
        handleSave={handleSave}
        formConfig={loadformConfig}
        // fullScreen={true}
      />
    </div>
  );
};
