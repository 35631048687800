import React, { useEffect, useState } from "react";
import { getTaxx } from "../../services/handlers/get_dropdowns";
import { toast } from "react-toastify";
import { getSubProduct } from "../../services/handlers/get_masters";
import { addSubProduct } from "../../services/handlers/add_masters";
import { deleteSubProduct } from "../../services/handlers/delete_masters";
import DynamicRawMaterials from "../../components/Dynamic_Raw_Materials";
import DynamicSubProduct from "../../components/Dynamic_SubProduct";
import { getRawMaterials } from "../../services/handlers/get_dropdowns";
import { getProductStages } from "../../services/handlers/get_dropdowns";
import { getProductsubStages } from "../../services/handlers/get_dropdowns";

export const MasterSubProduct = () => {
  const formConfig = [
    {
      field: "file_name",
      headerName: "Files",
      multiple: false,
      type: "image",
      imageType: "sub-product",
      width: 0,
      hide: true,
      editable: true,
    },
    // {
    //   field: "added_by",
    //   headerName: "Added By",
    //   width: 180,
    // },

    // {
    //   field: "modified_by",
    //   headerName: "Modified By",
    //   width: 180,
    // },
    // {
    //   field: "createdAt",
    //   headerName: "Added Date",
    //   type: "date",
    //   width: 180,
    // },
    // {
    //   field: "updatedAt",
    //   headerName: "Modified Date",
    //   type: "date",
    //   width: 180,
    // },
  ];

  const [loadformConfig, setFormConfig] = useState(formConfig);
  const [formState, setFormState] = useState({});
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);
  const [rawmaterial, setRawmaterial] = useState([]);
  const [stage, setStage] = useState([]);
  const [subStage, setSubStage] = useState([]);
  const [tax, setTax] = useState([]);
  const [rawMaterialErrors, setRawMaterialErrors] = useState([]);
  const [stageErrors, setStageErrors] = useState([]);
  const [subStageErrors, setSubStageErrors] = useState([]);
  const [taxErrors, setTaxErrors] = useState([]);
  const [stageSequenceErrors, setStageSequenceErrors] = useState([]);

  const validateFields = () => {
    const newRawMaterialErrors = rawmaterialList.map((item) =>
      item?.raw_material_id_fk ? false : true
    );
    const newStageErrors = rawmaterialList.map((item) =>
      item?.stage_id_fk ? false : true
    );
    const newSubStageErrors = rawmaterialList.map((item) =>
      item?.sub_stage_id_fk ? false : true
    );
    const newStageSequenceErrors = rawmaterialList.map((item) =>
      item?.stage_sequence_number >= 0 && item?.stage_sequence_number <= 9 ? false : true
    );

    const newTaxErrors = TaxList.map((item) =>
      item?.tax_percentage_id_fk ? false : true
    );

    setRawMaterialErrors(newRawMaterialErrors);
    setStageErrors(newStageErrors);
    setSubStageErrors(newSubStageErrors);
    setStageSequenceErrors(newStageSequenceErrors);
    setTaxErrors(newTaxErrors);
    return (
      newRawMaterialErrors.every((error) => !error) &&
      newStageErrors.every((error) => !error) &&
      newSubStageErrors.every((error) => !error) &&
      newStageSequenceErrors.every((error) => !error) &&
      newTaxErrors.every((error) => !error)
    );
  };

  const [TaxList, setTaxList] = useState([
    {
      tax_percentage_id: "",
    },
  ]);

  const handleTax = (index, key, value) => {
    setTaxList((prevTaxList) => {
      const updatedTaxList = [...prevTaxList];
      updatedTaxList[index][key] = value;

      return updatedTaxList;
    });
    handleChange("tax_percentage_id_fk", TaxList);
  };

  const [ProductNameAlias, setProductNameAlias] = useState([
    {
      product_name: "",
      product_alias: "",
    },
  ]);

  const handleNameChange = (index, key, value) => {
    setProductNameAlias((prevProductNameAlias) => {
      const updatedProductNameAlias = [...prevProductNameAlias];
      updatedProductNameAlias[index][key] = value;
      return updatedProductNameAlias;
    });
    handleChange("product_name", ProductNameAlias);
  };

  const handleAliasChange = (index, key, value) => {
    setProductNameAlias((prevProductNameAlias) => {
      const updatedProductNameAlias = [...prevProductNameAlias];
      updatedProductNameAlias[index][key] = value;
      return updatedProductNameAlias;
    });
    handleChange("product_alias", ProductNameAlias);
  };

  const [rawmaterialList, setRawmaterialList] = useState([
    {
      raw_material_id_fk: "",
      stage_id_fk: "",
      sub_stage_id_fk: "",
      stage_sequence_number: "",
    },
  ]);

  const handleAddRawMaterial = () => {
    setRawmaterialList([
      ...rawmaterialList,
      {
        raw_material_id_fk: "",
        stage_id_fk: "",
        sub_stage_id_fk: "",
        stage_sequence_number: "",
      },
    ]);
  };

  const handlerawmaterialListRemove = (index) => {
    const newList = rawmaterialList.filter((_, i) => i !== index);
    setRawmaterialList(newList);
  };

  const handleRawMaterialChange = (index, key, value) => {
    setRawmaterialList((prevrawmaterialList) => {
      const updatedrawmaterialList = [...prevrawmaterialList];
      updatedrawmaterialList[index][key] = value;

      return updatedrawmaterialList;
    });
    handleChange("raw_materials", rawmaterialList);
  };

  const handleChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    console.log(formState);
  };

  useEffect(() => {
    fetch();
  }, [reload]);

  async function fetch() {
    const response = await getSubProduct();
    setData(response);
  }

  useEffect(() => {
    const fetchRawmaterial = async () => {
      try {
        const data = await getRawMaterials();
        setRawmaterial(data);
        console.log("rawdata--->", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRawmaterial();
  }, []);

  useEffect(() => {
    const fetchProductStage = async () => {
      try {
        const data = await getProductStages();
        setStage(data);
        console.log("sdata--->", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductStage();
  }, []);

  useEffect(() => {
    const fetchProductSubStage = async () => {
      try {
        const data = await getProductsubStages();
        setSubStage(data);
        // console.log("sdata--->", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductSubStage();
  }, []);

  useEffect(() => {
    const fetchTax = async () => {
      try {
        const data = await getTaxx();
        setTax(data);
      } catch (error) {}
    };

    fetchTax();
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }
    try {
      const formattedFormState = {
        ...formState,
        tax_percentage_id_fk: formState.tax_percentage_id_fk.map(
          (item) => item.tax_percentage_id_fk
        ),
        product_name: formState.product_name.map((item) => item.product_name),
        product_alias: formState.product_alias.map(
          (item) => item.product_alias
        ),
      };
      await addSubProduct(formattedFormState);
      resetForms();
      toggleForm();
      setReload(!reload);
    } catch (error) {
      console.log(error);
    }
    console.log(formState);
  };

  const resetForms = () => {
    setRawmaterialList([
      {
        raw_material_id_fk: "",
        stage_id_fk: "",
        sub_stage_id_fk: "",
        stage_sequence_number: "",
      },
    ]);
    setTaxList([
      {
        tax_percentage_id: "",
      },
    ]);
    setProductNameAlias([
      {
        product_name: "",
        product_alias: "",
      },
    ]);
  };

  const handleDelete = async (id) => {
    try {
      await deleteSubProduct(id);
      setReload(!reload);
    } catch (error) {}
  };

  const toggleForm = () => {
    resetForms();
    setOpen(!open);
    setFormState({});
  };

  return (
    <div style={{ width: "78%", position: "fixed" }}>
      <DynamicSubProduct
        title="Sub Product"
        handleDelete={handleDelete}
        // handleUpdate={handleUpdate}
        toggleForm={toggleForm}
        column={formConfig}
        // handleCheckBox={handleCheckBox}
        formState={formState}
        canView={true}
        data={data}
      />
      <DynamicRawMaterials
        title="Add Sub Product"
        open={open}
        handleClose={toggleForm}
        formState={formState}
        handleChange={handleChange}
        handleRawMaterialChange={handleRawMaterialChange}
        handleAddRawMaterial={handleAddRawMaterial}
        handlerawmaterialListRemove={handlerawmaterialListRemove}
        rawmaterialList={rawmaterialList}
        rawmaterial={rawmaterial}
        handleNameChange={handleNameChange}
        handleAliasChange={handleAliasChange}
        ProductNameAlias={ProductNameAlias}
        stage={stage}
        subStage={subStage}
        // stageno={stageno}
        TaxList={TaxList}
        tax={tax}
        rawMaterialErrors={rawMaterialErrors}
        stageErrors={stageErrors}
        subStageErrors={subStageErrors}
        stageSequenceErrors={stageSequenceErrors}
        taxErrors={taxErrors}
        handleTax={handleTax}
        handleSave={handleSave}
        formConfig={loadformConfig}
        // fullScreen={true}
      />
    </div>
  );
};
